<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
     <!-- BOC:[error] -->
     <AError
        v-if="api.isError"
        :api="api"
        :callbackReset="() => (api.isError = false)"
      ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>Create New Child User</v-card-title>
     
      <div class="d-flex align-center justify-space-around">
       
        <div class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <v-text-field
                v-model="name"
                block
                label="Full Name (as in NRIC / Passport)"
                outlined
                clearable
                :rules="nameRules"
              ></v-text-field>
            </div>
            <div v-if="selectedType == 'child'" class="text-center pt-3">
              <SelectField
                :value="schoolYear"
                :field="selectSchoolYearField"
                @input="schoolYear = $event"
                label="School Year"
              ></SelectField>
            </div>
            <div class="text-center pt-3">
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Date of Birth"
                    readonly
                    outlined
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    :rules="dobRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  color="primary"
                  :max="
                    new Date(
                      Date.now() - new Date().getTimezoneOffset() * 60000
                    )
                      .toISOString()
                      .substring(0, 10)
                  "
                  min="1950-01-01"
                  @click:date="$refs.menu.save(date)"
                  @input="menu2 = false"
                >
                </v-date-picker>
              </v-menu>
            </div>
            <div class="text-center pt-1">
              <v-btn width="75%" :loading="api.isLoading" rounded color="primary" dark @click="submit()">
                Submit
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="$router.go(-1)"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import datasetSchoolYears from "@/datasets/schoolYear";
import datasetMonths from "@/datasets/month";
import datasetYears from "@/datasets/year";
import SelectField from "@/components/Form/SelectField.vue";
import moment from 'moment';
export default {
  components: {
    SelectField
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    menu2: false,
    // date: moment().format("YYYY-MM-DD"),
    date: null,
    menu: false,
    valid:true,
    name: null,
    nameRules:[],
    dobRules: [],
    step: 1,
     //BOC:[api]
     api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    userTypes: [
      {
        id: 1,
        name: "Adult",
        value: "adult",
      },
      {
        id: 2,
        name: "Child",
        value: "child",
      },
    ],
    selectedType: 'child',
    selectSchoolYearField: {
      defaultValue: 1,
      name: "School Year",
      options: datasetSchoolYears,
      optionText: "name",
      optionValue: "id",
    },
    schoolYear:1,
    selectMonthField: {
      defaultValue: 3,
      name: "Month",
      options: datasetMonths,
      optionText: "name",
      optionValue: "id",
    },
    month:3,

    selectYearField: {
      defaultValue: moment().format('YYYY').toString(),
      name: "Year",
      options: datasetYears,
      optionText: "",
      optionValue: "",
    },
    year:moment().format('YYYY').toString(),
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: "Dashboard",
      to: { name: "PageFamilyDashboard" },
      exact: true,
    });

    this.breadcrumbs.push({
      text: "New Child User",
      to: { name: "PageFamilyUserAdd" },
      exact: true,
    });

    //EOC
    this.api.url =
      this.$api.servers.sso +
      "/api/v2/" +
      this.$_getLocale() +
      "/backend/user/add";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      this.$router.push({ name: "PageFamilyDashboard" });
    };
  },
  mounted() {
    //
  },
  watch:{
    name: function () {
      this.nameRules = [];
    },
  },
  methods: {
    close() {
      this.$router.push({ name: "PageFamilyDashboard" });
    },
    submit() {
      this.nameRules = [
      (v) => !!v || "Name is required",
      ];
      this.dobRules = [(v) => !!v || "Date of Birth is required"];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formName.validate()) {
          self.api.params = {
            name: self.name,
            month : self.selectedType == 'child'? self.month : null,
            schoolYear:self.selectedType == 'child'? self.schoolYear : null,
            year: self.selectedType == 'child'? self.year : null,
            type: self.selectedType,
            dateOfBirth: self.date,
          };
          self.$api.fetch(self.api);
        } else {
          self.api.callbackError("Please check the form.");
        }
      });
    },
    selectUserType(item){
      this.selectedType = item.value
      this.step++;
    }
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>