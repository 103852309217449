<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <div class="d-flex mb-5">
      <v-spacer></v-spacer>
      <v-btn class="mx-1" :loading="loading" @click="getQuestions">
        {{ $t("action.regenerateQuestion") }}
      </v-btn>
      <v-btn
        class="mx-1"
        :to="{
          name: 'PageMainDiscoverQuiz',
          params: { code: $route.params.code },
        }"
      >
        {{ $t("action.testPlay") }}
      </v-btn>
    </div>
    <v-card
      :loading="loading"
      outlined
      class="mx-auto mb-3"
      max-width="598"
      elevation="2"
    >
      <v-card-text>
        <div class="font-weight-small text-caption mb-5">
          {{ syllabus.name }}, {{ $t("model.name.chapter") }} ({{ chapter.sort }})
          {{ chapter.name }}
        </div>
        <SampleQuestion v-if="reload" />
      </v-card-text>
      <v-card-actions class="justify-end"> </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import SampleQuestion from "../../components/SampleQuestion";
export default {
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    dialogs: {
      view: false,
    },
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    apiChapter: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
    loading: false,
    reload: true,
    syllabus: {},
    chapter:{},
  }),
  components: {
    SampleQuestion,
  },
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("route.PageMainHome"),
      to: { name: "PageMainHome" },
      exact: true,
    });
    this.breadcrumbs.push({
      text: this.$t("route.PageMainDiscover"),
      to: { name: "PageMainDiscover" },
      exact: true,
    });
    this.breadcrumbs.push({
      text: this.$t("route.PageMainDiscoverSample"),
      to: { name: "PageMainDiscoverSample" },
    });
    //EOC
    //BOC:[api]
    this.api.method = "GET";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
      var sysKey = this.$route.params.code.split("_")[0];
      this.syllabus = this.$_.find(this.data, function (obj) {
        if (obj.key == sysKey) {
          return true;
        }
      });
this.fetchChapter()
    };

    this.apiChapter.method = "GET";
    this.apiChapter.callbackReset = () => {
      this.apiChapter.isLoading = true;
      this.apiChapter.isError = false;
    };
    this.apiChapter.callbackError = (e) => {
      this.apiChapter.isLoading = false;
      this.apiChapter.isError = true;
      this.apiChapter.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.apiChapter.callbackSuccess = (resp) => {
      this.apiChapter.isLoading = false;
      var chapterKey = this.$route.params.chapterkey;
      this.chapter = this.$_.find(resp, function (obj) {
        if (obj.key == chapterKey) {
          return true;
        }
      });
    };
    //EOC
  },
  methods: {
    fetch() {
      this.api.url =
        this.$api.servers.question + "/v2/syllabi?lang=" + this.$_getLocale();
      this.$api.fetch(this.api);
    },
    fetchChapter() {
      this.apiChapter.url =
        this.$api.servers.question + "/v2/syllabi/"+this.syllabus.key+"/chapters?lang=" + this.$_getLocale();
      this.$api.fetch(this.apiChapter);
    },
    getQuestions() {
      this.loading = true;
      this.reload = false;
      setTimeout(() => {
        this.reload = true;
        this.loading = false;
      }, 200);
    },
  },
  beforeMount() {
    this.fetch();
  },
};
</script>

<style>
.chapter {
  color: black;
}
.main::before {
  background-color: transparent !important;
}
</style>