
import Moderator from '@/models/bread/Moderator/planFeature'


export default {
  key:'PlanFeature',
  name:{
    singular:'Feature',
    plural:'Feature',
  },
  parents:[
    'feature',
  ],
  grandParents:[
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}