<template>
<div class="grey lighten-3 my-5 py-10 px-5">
  <div class="text-h3 mb-10 text-center"><span class="mouse-memoirs">{{ title[locale] }}</span></div>
  <v-row>
    <v-col v-for="(item, index) in items" sm="6" md="3" :key="index" class="d-flex flex-column align-center mb-4">
      <div>
        <v-img 
          v-if="item.imagePath" 
          :src="item.imagePath" 
          :lazy-src="`https://placehold.co/200x200?text=${item.name[locale]}`"
          class="mb-2"
          style="border-radius: 50%; max-width: 200px;"
          data-aos="flip-left"
          :data-aos-delay="index * 100"
        ></v-img>
      </div>
      <div class="text-center text-h6 text-uppercase">{{ item.name[locale] }}</div>
    </v-col>
  </v-row>
</div>
</template>

<script>
import { mapState } from 'vuex'
import AvatarBeary from '@/assets/PageMainHomeV6/AvatarBeary.png'
import AvatarKaty from '@/assets/PageMainHomeV6/AvatarKaty.png'
import AvatarHamochi from '@/assets/PageMainHomeV6/AvatarHamochi.png'
import AvatarRuby from '@/assets/PageMainHomeV6/AvatarRuby.png'
export default {
  components:{
    //
  },
  computed: mapState({
    //
  }),
  props:[
    //
  ],
  data: () => ({
    locale: "en",
    title: {
      en: "Meet Hamochi and his friends ↯",
      ms: "Kenali Hamochi dan rakan-rakannya ↯",
      zh: "认识 Hamochi 和他的朋友们 ↯",
    },
    items: [
      {
        imagePath: AvatarHamochi,
        name: {
          en:"Hamochi",
          ms:"Hamochi",
          zh:"Hamochi",
        },
      },
      {
        imagePath: AvatarKaty,
        name: {
          en:"Katy",
          ms:"Katy",
          zh:"凯帝",
        },
      },
      {
        imagePath: AvatarBeary,
        name: {
          en:"Beary",
          ms:"Beary",
          zh:"贝里",
        },
      },
      {
        imagePath: AvatarRuby,
        name: {
          en:"Ruby",
          ms:"Ruby",
          zh:"路比",
        },
      },
    ],
  }),
  created() {
    //
  },
  mounted() {
    this.locale = this.$_getLocale();
  },
  methods: {
    //
  }
}
</script>

<style scoped>
.mouse-memoirs {
  font-family: "Mouse Memoirs", sans-serif;
}
</style>