import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import '@/mixins/locale'
import '@/mixins/route'
import '@/mixins/string'
import _ from 'lodash'
import moment from 'moment'
import './assets/css/app.css';

//BOC
import {version} from './../package.json'
Vue.prototype.$version = version
//EOC
//BOC:[api]
import axios from 'axios'
Vue.prototype.$axios = axios
var api = {
  servers: {
    analysis: process.env.VUE_APP_URL_API_ANALYSIS,
    event: process.env.VUE_APP_URL_API_EVENT,
    game: process.env.VUE_APP_URL_API_GAME,
    job: process.env.VUE_APP_URL_API_JOB,
    log: process.env.VUE_APP_URL_API_LOG,
    question: process.env.VUE_APP_URL_API_QUESTION,
    sso: process.env.VUE_APP_URL_API_SSO,
    classroom:process.env.VUE_APP_URL_API_CLASSROOM,
  },
  fetch: async(props) => {
    var params = {}
    var headers = {}
    var method = 'post'
    if (typeof props.params != 'undefined') params = props.params
    if (typeof props.method != 'undefined') method = props.method
    if (typeof props.headers != 'undefined') headers = props.headers
      //BOC:[auto insert token]
    if (typeof store.state.auth.data.Session != 'undefined' && store.state.auth.data.Session) headers.Authorization = 'Bearer ' + store.state.auth.data.Session.token
      //EOC
    props.callbackReset();
    try {
      await axios({
          method: method,
          headers: headers,
          url: props.url,
          data: params,
        })
        .then(
          (response) => {
            if (response.data == null) {
              props.callbackError('Item not found.');
            } else if (response.data.error) {
              props.callbackError(response.data.error);
            } else {
              props.callbackSuccess(response.data);
            }
          },
          (error) => {
            if (typeof error.response != 'undefined' && typeof error.response.data != 'undefined') {
              var err;
              if (typeof error.response.data.errors != 'undefined' && error.response.data.errors) {
                //read Laravel ValidationException errors message
                var errors = error.response.data.errors;
                err = errors[Object.keys(errors)[0]];
              } else if (typeof error.response.data.message != 'undefined') {
                err = error.response.data.message;
                 //BOC:[locale]
                 var locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1')
                 if (locale == '/') locale = 'en'
                   //EOC
                   if(error.response.data.code == '403'){
                    window.location.replace('/' + locale + '/error/restricted');
                   }
                if (err == 'Authorization token expired') {
                 
                  window.location.replace('/' + locale + '/error/expired');
                }

              } else {
                err = error;
              }
              props.callbackError(err);
            } else {
              props.callbackError(error);
            }
          }
        );
    } catch (e) {
      props.callbackError(e);
    }
  },
}
Vue.prototype.$api = api
  //EOC
  //BOC
Vue.prototype.$_ = _
  //EOC
  //BOC
Vue.prototype.$moment = moment
  //EOC
  //BOC:[components]
Vue.component('AAvatar', require('./components/AAvatar.vue').default);
Vue.component('AButtonShare', require('./components/AButtonShare.vue').default);
Vue.component('ABreadcrumb', require('./components/ABreadcrumbV1.vue').default);
Vue.component('ABreadcrumbV1', require('./components/ABreadcrumbV1.vue').default);
Vue.component('AError', require('./components/AError.vue').default);
Vue.component('AErrorV1', require('./components/AErrorV1.vue').default);
Vue.component('ALoader', require('./components/ALoader.vue').default);
Vue.component('ASkeletonLoader', require('./components/ASkeletonLoader.vue').default);
Vue.component('ATitle', require('./components/ATitle.vue').default);
//EOC
//BOC:[meta]
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
  //EOC

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')