import Moderator from '@/models/bread/Moderator/accountBook'

export default {
  key:'Book',
  server:'game',
  name:{
    singular:'Book',
    plural:'Books',
  },
  parents:[
    'Account',
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}