<template>
  <div class="square grey lighten-5">
    <div class="content">
      <v-img
        v-if="item.Sponsor.logoUrl"
        :src="item.Sponsor.logoUrl"
        contain
      >
      </v-img>
      <div v-else>
        <a
          v-if="item.Sponsor.websiteUrl"
          href="item.Sponsor.websiteUrl"
          target="_blank"
          class="text-h6 blue--text text--darken-2"
          >{{ item.Sponsor.name }}</a
        >
        <div v-else>{{ item.Sponsor.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components:{
    //
  },
  computed: mapState({
    //
  }),
  props:[
    'item',
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  }
}
</script>

<style scoped>
.square {
  position: relative;
  width: 100%; /* Full width of the column */
  padding-top: 100%; /* Height equals width for a perfect square */
  /* background-color: rgba(0,0,0,0.05); */
  background-color: white;
  border: 1px solid #ccc;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>