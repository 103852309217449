import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors"; // Import colors utility

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: colors.orange.darken3, // Change primary to orange
        // other theme colors can remain the same or customized
      },
      dark: {
        primary: colors.orange.lighten1, // Optionally set for dark mode
      },
    },
  },
});
