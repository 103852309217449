
import i18n from '@/plugins/i18n'
export default {
  browse:{
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text:  'Key',
          value: 'key',
        },
        {
          text:  'Published',
          value: 'isPublished',
        },
        {
          text:  'Price',
          value: 'priceOriginal',
        },
        {
          text:  'Discount',
          value: 'discount',
        },
        {
          text: 'Start',
          value: 'timestampStart',
          filterable: false,
        },
        {
          text: "End",
          value: 'timestampEnd',
          filterable: false,
        },
      ],
    },
  },
  read:{
    tabs: [
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text:  'Sort',
          value: 'sort',
        },
        {
          text:  'Key',
          value: 'key',
        },
        {
          text:  'Country Code',
          value: 'countryCode',
        },
        {
          text:  'Published',
          value: 'isPublished',
        },
        {
          text:  'Price',
          value: 'priceOriginal',
        },
        {
          text:  'Currency',
          value: 'currencyType',
        },
        {
          text:  'Discount',
          value: 'discount',
        },
        {
          text:  'Duration',
          value: 'duration',
        },
        {
          text:  'Duration Unit',
          value: 'durationUnit',
        },
        {
          text: 'Start',
          value: 'timestampStart',
          filterable: false,
        },
        {
          text: "End",
          value: 'timestampEnd',
          filterable: false,
        },
        {
          text: 'Status',
          value: 'subscriptionStatus',
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add:false,
  edit:true,
  delete:true,
}