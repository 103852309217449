<template>
<v-row style="overflow-x: hidden;">
<v-col v-for="(item, index) in items" :key="index" sm="6" md="3">
  <v-card  class="mb-3 fill-height" data-aos="fade-left"
    :data-aos-delay="index * 100">
    <v-img :src="item.imagePath" lazy cover></v-img>
    <div class="d-flex flex-column justify-center align-center flex-grow-1 text-center">
      <v-card-title>{{ item.name[locale] }}</v-card-title>
      <v-card-actions>
        <v-btn v-if="item.route" text block color="primary" :to="item.route">{{ cta[locale] }}</v-btn>
      </v-card-actions>
    </div>
  </v-card>
</v-col>
</v-row>
</template>

<script>
import { mapState } from 'vuex'
import ImageArt from '@/assets/PageMainHomeV6/Banner640Art.png'
import ImageEvent from '@/assets/PageMainHomeV6/Banner640Event.png'
import ImageLanguage from '@/assets/PageMainHomeV6/Banner640ABC.png'
import ImageMath from '@/assets/PageMainHomeV6/Banner640MathMastery.png'
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    isMobile: false,
    cta: {
      en: "LEARN MORE",
      ms: "BACA MAKLUMAT LANJUT",
      zh: "查看详情",
    },
    items: [
      {
        imagePath: ImageMath,
        name: {
          en: "Practise Maths",
          ms: "Berlatih Matematik",
          zh: "练习数学",
        },
        description: {
          en: "Master math effortlessly with free daily practice sessions and engaging mini-games.",
          ms: "Kuasi matematik dengan mudah melalui sesi latihan harian percuma dan permainan mini yang menarik.",
          zh: "通过免费的每日练习和有趣的小型游戏轻松掌握数学。",
        },
      },
      {
        imagePath: ImageLanguage,
        name: {
          en: "Read Stories",
          ms: "Baca Cerita",
          zh: "阅读故事",
        },
        description: {
          en: "Learn languages through fun and interactive storybooks featuring voiceovers and translations.",
          ms: "Belajar bahasa melalui buku cerita yang menyeronokkan dan interaktif dengan suara latar dan terjemahan.",
          zh: "通过有趣和互动的故事书学习语言，这些故事书配有语音和翻译。",
        },
      },
      {
        imagePath: ImageArt,
        name: {
          en: "Express Creativity in Arts",
          ms: "Ekspresikan Kreativiti dalam Seni",
          zh: "绘画创作",
        },
        description: {
          en: "Enjoy a creative and relaxing activity where the children can draw and share their artworks.",
          ms: "Nikmati aktiviti kreatif dan santai di mana kanak-kanak boleh melukis dan berkongsi karya seni mereka.",
          zh: "享受一项创意和放松的活动，让孩子们绘画并分享他们的艺术作品。",
        },
      },
      {
        imagePath: ImageEvent,
        name: {
          en: "Join Events",
          ms: "Sertai Acara",
          zh: "参加活动",
        },
        description: {
          en: "Join thrilling holiday events where your children can compete and collaborate!",
          ms: "Sertai acara cuti yang mendebarkan di mana anak-anak anda boleh bersaing dan bekerjasama!",
          zh: "参加刺激的假期活动，让您的孩子可以竞争与合作！",
        },
        route: {
          name: "PageMainEvent",
        },
      },
    ]
    ,
    locale: "en",
  }),
  created() {
    //
  },
  mounted() {
    this.locale = this.$_getLocale();
    this.isMobile = window.innerWidth < 768; // Define mobile breakpoint
  },
  methods: {
    //
  }
}
</script>