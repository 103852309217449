<template>
  <transition name="fade">
    <v-dialog v-model="confirm" max-width="300" persistent>
      <v-card>
        <v-card-title
          class="text-h5 teal--text text--darken-3 d-flex justify-space-between pt-0 pe-0"
        >
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="ma-3"
            @click="
              () => {
                $emit('exitConfirm');
              }
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="text-center">
          <v-img
            :src="require('@/assets/hamochConfirm.png')"
            max-width="200"
            class="mx-auto"
          ></v-img>
          <div
            v-if="type && type == 'math'"
            class="orange lighten-5 orange--text text-center mx-3 my-3 px-5 py-5 rounded-xl text-h6"
          >
            {{ $t("string.recalculateScore") }}
          </div>
          <div
            v-else
            class="orange lighten-5 orange--text text-center mx-3 my-3 px-5 py-5 rounded-xl text-h6"
          >
            {{ $t("string.recalculateStar") }}
          </div>
          <div>
            <v-btn
              small
              dark
              color="orange"
              @click="recalculate()"
              :loading="api.isLoading"
            >
              {{ $t("action.recalculate") }}
              <v-icon right> mdi-reload </v-icon>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  props: ["type", "confirm", "eventCode", "mochiId"],
  computed: mapState({}),
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
  }),
  created() {
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isError = true;
      this.api.error = e;
      this.api.isLoading = false;
      window.location.reload();
    };
    this.api.callbackSuccess = () => {
      window.location.reload();
      this.api.isLoading = false;
    };
  },
  methods: {
    recalculate() {
      this.api.params = {
      eventCode: this.eventCode,
      mochiId: this.mochiId,
    };
      this.api.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/patch/240212/recalculateTargetPlayerScoreBasedOnSubmissionLog/2/update";

      this.$api.fetch(this.api);
    },
  },
  mounted() {},
};
</script>
