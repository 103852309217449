import Moderator from '@/models/bread/Moderator/chapterReport'

export default {
  key:'ChapterReport',
  name:{
    singular:'Chapter',
    plural:'Chapters',
  },
  parents:[
    'Book'
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}