import Moderator from '@/models/bread/Moderator/plan'

export default {
  key:'Plan',
  server:'sso',
  name:{
    singular:'Plan',
    plural:'Plans',
  },
  parents:[],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}