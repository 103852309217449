<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <AError
      v-if="otpApi.isError"
      :api="otpApi"
      :callbackReset="() => (otpApi.isError = false)"
    ></AError>
    <AError
      v-if="otpCheckApi.isError"
      :api="otpCheckApi"
      :callbackReset="() => (otpCheckApi.isError = false)"
    ></AError>
    <AError
        v-if="accountCheckApi.isError"
        :api="accountCheckApi"
        :callbackReset="() => (accountCheckApi.isError = false)"
      ></AError>
    <!-- EOC -->
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-card-title>Forgot Password</v-card-title>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center" v-if="step == 1">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <Mobile
                :value="mobile"
                label="Mobile number"
                :rules="mobileRules"
                @input="mobile = $event"
                @getCode="code = $event"
              ></Mobile>
            </div>
            <div></div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                rounded
                color="primary"
                dark
                :loading="otpApi.isLoading"
                @click="checkAcccount()"
              >
                Submit
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="close()"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
        </div>
        <div v-else-if="step == 2" class="form-content text-center">
          <v-form ref="formOtp" v-model="valid" lazy-validation>
            <div>
              We sent One Time Password (OTP) to
              {{ this.mobile }}.
            </div>
            <div class="text-center pt-3">
              <v-text-field
                :rules="[
                  (v) => !!v || 'OTP is required',
                  () =>
                    Number.isInteger(Number(otp)) ||
                    'The OTP must be an integer number',
                ]"
                v-model="otp"
                length="6"
                block
                label="OTP"
                outlined
                clearable
              ></v-text-field>
            </div>
            <div>
              Didn't receive?
              <span v-if="resendTime > 0"> Wait {{ resendTime }} seconds.</span
              ><span v-else
                ><v-btn
                  color="primary"
                  text
                  @click="reSendOtp()"
                  :loading="otpApi.isLoading"
                >
                  Resend OTP
                </v-btn></span
              >
            </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                rounded
                color="primary"
                dark
                :loading="otpCheckApi.isLoading"
                @click="validateOtp()"
              >
                Verify
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="decrease()"
              >
                Back
              </v-btn>
            </div>
          </v-form>
        </div>
        <div v-else-if="step == 3" class="form-content text-center">
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <div class="text-center pt-1">
                <TextField
                  :value="newPassword"
                  label="New Password"
                  :rules="newPasswordRules"
                  type="password"
                  @input="newPassword = $event"
                ></TextField>
              </div>
              <div class="text-center pt-1">
                <TextField
                  :value="confirmPassword"
                  label="Confirm Password"
                  :rules="confirmPasswordRules"
                  type="password"
                  @input="confirmPassword = $event"
                ></TextField>
              </div>
            </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                rounded
                color="primary"
                dark
                :loading="api.isLoading"
                @click="submitPassword()"
              >
                Submit
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="close()"
              >
                Cancel
              </v-btn>
            </div>
          </v-form>
          <div v-if="isError">
            <div>
              <v-icon size="150"> mdi-information-slab-circle-outline </v-icon>
            </div>
            <div class="text-h5 pt-1 font-weight-black">Oops!</div>
            <div class="pt-2">This link has been broken or expired.</div>
            <div class="text-center pt-3">
              <v-btn
                width="75%"
                rounded
                color="primary"
                :to="{ name: 'PageMainHome' }"
                dark
              >
                Go to Home
              </v-btn>
            </div>
          </div>
        </div>
        <div v-else class="form-content text-center">
          <div class="text-center pt-2">
            <v-progress-circular
              :size="80"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import Mobile from "@/components/Form/Mobile.vue";
import TextField from "@/components/Form/TextField.vue";
export default {
  components: { Mobile, TextField },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.confirmPassword || "Password must match";
    },
  },
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    valid: true,
    name: null,
    mobile: null,
    otp: null,
    nameRules: [],
    mobileRules: [],
    step: 1,
    resendTime: 60,
    code: "MYS",
    setInterval: null,
    selectedCountry: {},
    countryCode: null,
    countries: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    otpApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    otpCheckApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
       //BOC:[api]
    accountCheckApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    newPassword: null,
    confirmPassword: null,
    confirmPasswordRules: [],
    newPasswordRules: [],
  }),
  watch: {
    mobile: function () {
      this.mobileRules = [];
    },
    resendTime: function () {
      if (this.resendTime < 1) {
        clearInterval(this.setInterval);
      }
    },
  },
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: "Home",
      to: { name: "PageMainHome" },
      exact: true,
    });

    this.breadcrumbs.push({
      text: "Forgot Password",
      to: { name: "PageAccountForgetPassword" },
      exact: true,
    });

    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/account/resetPassword";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      this.$router.push({ name: "PageMainLogin" });
    };

    this.otpApi.url =
      this.$api.servers.log + "/api/v1/" + this.$_getLocale() + "/otp/send";

    this.otpApi.callbackReset = () => {
      this.otpApi.isLoading = true;
      this.otpApi.isError = false;
    };

    this.otpApi.callbackError = (e) => {
      this.otpApi.isLoading = false;
      this.otpApi.isError = true;
      this.otpApi.error = e;
    };

    this.otpApi.callbackSuccess = () => {
      this.otpApi.isLoading = false;
      this.step = 2;
      this.resendTime = 60;
      this.startTime();
    };

    this.accountCheckApi.url =
      this.$api.servers.sso + "/api/v1/" + this.$_getLocale() + "/account/checkMobile";

    this.accountCheckApi.callbackReset = () => {
      this.accountCheckApi.isLoading = true;
      this.otpApi.isLoading = true;
      this.accountCheckApi.isError = false;
    };

    this.accountCheckApi.callbackError = (e) => {
      this.accountCheckApi.isLoading = false;
      this.otpApi.isLoading = false;
      this.accountCheckApi.isError = true;
      this.accountCheckApi.error = e;
    };

    this.accountCheckApi.callbackSuccess = (resp) => {
      this.accountCheckApi.isLoading = false;
      if(resp.isHasAccount){
        this.sendOtp()
      }else{
        this.accountCheckApi.callbackError("The account not found.");
      }
    };


    this.otpCheckApi.url =
      this.$api.servers.log + "/api/v1/" + this.$_getLocale() + "/otp/check";

    this.otpCheckApi.callbackReset = () => {
      this.otpCheckApi.isLoading = true;
      this.otpCheckApi.isError = false;
    };

    this.otpCheckApi.callbackError = (e) => {
      this.otpCheckApi.isLoading = false;
      this.otpCheckApi.isError = true;
      this.otpCheckApi.error = e;
    };

    this.otpCheckApi.callbackSuccess = () => {
      this.otpCheckApi.isLoading = false;
      this.step++;
    };
  },
  mounted() {
    // this.mobile = this.auth.Account.mobile;
    // this.sendOtp();
  },

  methods: {
    close() {
      this.$router.push({ name: "PageMainLogin" });
    },
    increase() {
      this.step++;
    },
    decrease() {
      this.step--;
      this.otp = null;
    },
    submit() {
      this.mobileRules = [
        () =>
          (!!this.mobile &&
            this.mobile.length >= 7 &&
            this.mobile.length <= 15) ||
          "Mobile number has to be between 7 and 15",
        () =>
          Number.isInteger(Number(this.mobile)) ||
          "The Mobile number must be an integer number",
      ];

      let self = this;
      setTimeout(function () {
        if (self.$refs.form.validate()) {
          self.api.params = self.$_.clone({
            mobile: self.mobile,
            countryCode: self.code,
          });
          self.sendOtp();
        } else {
          self.api.callbackError("Please check the form.");
        }
      });
    },
    checkAcccount() {
      //this.otpApi.callbackReset();
      this.mobileRules = [
        () =>
          (!!this.mobile &&
            this.mobile.length >= 7 &&
            this.mobile.length <= 15) ||
          "Mobile number has to be between 7 and 15",
        () =>
          Number.isInteger(Number(this.mobile)) ||
          "The Mobile number must be an integer number",
      ];
      let self = this;
      setTimeout(function () {
        if (self.$refs.form.validate()) {
          self.accountCheckApi.params = {
            mobile: self.mobile,
          };
          
          self.$api.fetch(self.accountCheckApi);
        } else {
          self.accountCheckApi.callbackError("Please check the form.");
        }
      });
    },
    submitPassword() {
      this.newPasswordRules = [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Min 8 characters",
      ];
      this.confirmPasswordRules = [
        (v) => !!v || "Password is required",
        this.passwordConfirmationRule,
      ];
      let self = this;
      setTimeout(function () {
        if (self.$refs.form.validate()) {
          self.api.params = {
            password: self.newPassword,
            mobile: self.mobile,
          };
          self.$api.fetch(self.api);
        } else {
          self.api.callbackError("Please check the form.");
        }
      });
    },
    sendOtp() {
      this.otpApi.params = {
        mobile: this.mobile,
        countryCode: this.code,
        purpose: "password_reaset",
      };
      this.$api.fetch(this.otpApi);
    },
    reSendOtp() {
      this.otpApi.callbackReset();

      this.otpApi.params = {
        mobile: this.mobile,
        countryCode: this.code,
        purpose: "password_reaset",
      };
      this.$api.fetch(this.otpApi);
    },
    validateOtp() {
      if (this.$refs.formOtp.validate()) {
        this.otpCheckApi.params = {
          otp: this.otp,
          mobile: this.mobile,
          purpose: "password_reaset",
        };
        this.$api.fetch(this.otpCheckApi);
      } else {
        this.otpApi.callbackError("Please check the form.");
      }
    },
    startTime() {
      this.setInterval = setInterval(() => {
        if (this.resendTime > 0) {
          this.resendTime = this.resendTime - 1;
        }
      }, 1000);
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>