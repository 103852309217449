export default {
  Admin: [{
      name: 'Accounts',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'account' } },
    },
    {
      name: 'Building',
      icon: 'mdi-domain',
      route: { name: 'PageAdminDataBrowse', params: { object: 'building' } },
    },
    {
      name: 'Syllabuses',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'syllabus' } },
    },
    {
      name: 'Chapters',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'chapter' } },
    },
    {
      name: 'Questions',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'question' } },
    },
    {
      name: 'Question Sets',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'questionSet' } },
    },
    {
      name: 'Question Set Questions',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'questionSetQuestion' } },
    },
    {
      name: 'Games',
      icon: 'mdi-gamepad-variant',
      route: { name: 'PageAdminDataBrowse', params: { object: 'game' } },
    },
    {
      name: 'Modules',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'module' } },
    },
    {
      name: 'Items',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'item' } },
    },
    {
      name: 'Shops',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'shop' } },
    },
    {
      name: 'Shop Items',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'shopItem' } },
    },
    {
      name: 'Random Items',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'randomItem' } },
    },
    {
      name: 'Schools',
      icon: 'mdi-bank',
      route: { name: 'PageAdminDataBrowse', params: { object: 'school' } },
    },
    {
      name: 'Classrooms',
      icon: 'mdi-google-classroom',
      route: { name: 'PageAdminDataBrowse', params: { object: 'classroom' } },
    },
    {
      name: 'ClassroomOnEvents',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'classroomOnEvent' } },
    },
    {
      name: 'Teachers',
      icon: 'mdi-account-tie',
      route: { name: 'PageAdminDataBrowse', params: { object: 'teacher' } },
    },
    {
      name: 'Students',
      icon: 'mdi-account-group',
      route: { name: 'PageAdminDataBrowse', params: { object: 'student' } },
    },
    {
      name: 'Transcripts',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'transcript' } },
    },
    {
      name: 'Saves',
      icon: 'mdi-content-save',
      route: { name: 'PageAdminDataBrowse', params: { object: 'save' } },
    },
    {
      name: 'Events',
      icon: 'mdi-calendar-star',
      route: { name: 'PageAdminDataBrowse', params: { object: 'event' } },
    },
    {
      name: 'Checkpoints',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'checkpoint' } },
    },
    {
      name: 'Prize Types',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'prizeType' } },
    },
    {
      name: 'Prize Type Rewards',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'prizeTypeReward' } },
    },
    {
      name: 'Prizes (Game)',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'gamePrize' } },
    },
    {
      name: 'Prizes (Event)',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'eventPrize' } },
    },
    {
      name: 'Prize Winners',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'winner' } },
    },
    {
      name: 'Achievements',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'achievement' } },
    },
    {
      name: 'Achievement Rewards',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'achievementReward' } },
    },
    {
      name: 'Achievement Progresss',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'achievementProgress' } },
    },
    {
      name: 'Participants',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'participant' } },
    },
    {
      name: 'Inventory',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'inventory' } },
    },
    {
      name: 'Submissions',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'submission' } },
    },
    {
      name: 'Records',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'record' } },
    },
    {
      name: 'Logs',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'log' } },
    },
    {
      name: 'Seeds',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'seed' } },
    },
    {
      name: 'Dummies',
      icon: 'mdi-database',
      route: { name: 'PageAdminDataBrowse', params: { object: 'dummy' } },
    },
  ],
  Moderator: [
    {
      name: 'Accounts',
      translatableName:'model.name.accounts',
      icon: 'mdi-account',
      route: { name: 'PageModeratorAccountBrowse' },
      path: 'account',
      divider: false,
      server:'sso'
    },
    {
      name: 'Users',
      translatableName:'model.name.users',
      icon: 'mdi-account-group',
      route: { name: 'PageModeratorUserBrowse' },
      path: 'user',
      divider: false,
      server:'sso'
    },
    {
      name: 'Players',
      translatableName:'model.name.players',
      icon: 'mdi-run',
      route: { name: 'PageModeratorPlayerBrowse' },
      path: 'player',
      divider: false,
      server:'game'
    },
    {
      name: 'Sponsors',
      translatableName:'model.name.sponsors',
      icon: 'mdi-cash-100',
      route: { name: 'PageModeratorSponsorBrowse' },
      path: 'sponsor',
      divider: true,
      server:'event'
    },
    {
      name: 'Applications',
      translatableName:'model.name.applications',
      icon: 'mdi-note-text',
      route: { name: 'PageModeratorApplicationBrowse' },
      path: 'application',
      divider: false,
      server:'sso'
    },
   
    {
      name: 'Institutions',
      translatableName:'model.name.institutions',
      icon: 'mdi-cast-education',
      route: { name: 'PageModeratorInstitutionBrowse' },
      path: 'institution',
      divider: false,
      server:'sso'
    },
    {
      name: 'Classrooms',
      translatableName:'model.name.classrooms',
      icon: 'mdi-google-classroom',
      route: { name: 'PageModeratorClassroomBrowse' },
      path: 'classroom',
      divider: false,
      server:'sso'
    },
    {
      name: 'Teachers',
      translatableName:'model.name.teachers',
      icon: 'mdi-human-male-board',
      route: { name: 'PageModeratorTeacherBrowse' },
      path: 'teacher',
      divider: false,
      server:'sso'
    },
    {
      name: 'Students',
      translatableName:'model.name.students',
      icon: 'mdi-account-school',
      route: { name: 'PageModeratorStudentBrowse' },
      path: 'student',
      divider: true,
      server:'sso'
    },
    {
      name: 'Plans',
      translatableName:'model.name.plans',
      icon: 'mdi-license',
      route: { name: 'PageModeratorPlanBrowse' },
      path: 'plan',
      divider: false,
      server:'sso'
    },
    {
      name: 'Features',
      translatableName:'model.name.features',
      icon: 'mdi-star-circle-outline',
      route: { name: 'PageModeratorFeatureBrowse' },
      path: 'feature',
      divider: true,
      server:'sso'
    },
    {
      name: 'News',
      translatableName:'model.name.news',
      icon: 'mdi-bullhorn-variant-outline',
      route: { name: 'PageModeratorNewsBrowse' },
      path: 'news',
      divider: true,
      server:'game'
    },
    {
      name: 'Themes',
      translatableName:'model.name.themes',
      icon: 'mdi-brush',
      route: { name: 'PageModeratorThemeBrowse' },
      path: 'gallery',
      divider: false,
      server:'game'
    },
    {
      name: 'Books',
      translatableName:'model.name.books',
      icon: 'mdi-book-open-page-variant',
      route: { name: 'PageModeratorBookBrowse' },
      path: 'book',
      divider: false,
      server:'game'
    },
    {
      name: 'Series',
      translatableName:'model.name.series',
      icon: 'mdi-calendar-clock',
      route: { name: 'PageModeratorSeriesBrowse' },
      path: 'series',
      divider: false,
      server:'event'
    },
    {
      name: 'Events',
      translatableName:'model.name.events',
      icon: 'mdi-calendar-star',
      route: { name: 'PageModeratorEventBrowse' },
      path: 'event',
      divider: false,
      server:'event'
    },
    {
      name: 'Finishers',
      translatableName:'model.name.finishers',
      icon: 'mdi-gift',
      route: { name: 'PageModeratorFinisherBrowse' },
      path: 'finisher',
      divider: true,
      server:'event'
    },
    {
      name: 'Daily Game Progress',
      translatableName:'model.name.dailyGameProgress',
      icon: 'mdi-clock-start',
      route: { name: 'PageModeratorPlayerDailyGameProgressBrowse' },
      path: 'dailyGameProgress',
      divider: true,
      server:'game'
    },
    // {
    //   name: 'Jobs',
    //   translatableName:'model.name.jobs',
    //   icon: 'mdi-briefcase',
    //   route: { name: 'PageModeratorJobBrowse' },
    //   path: 'job',
    //   divider: true,
    //   server:'job'
    // },
  ],
  Teacher: [
    {
      name: 'Classrooms',
      translatableName:'model.name.classrooms',
      icon: 'mdi-google-classroom',
      route: { name: 'PageTeacherClassroomBrowse' },
      path: 'classroom',
    },
    {
      name: 'Events',
      translatableName:'model.name.events',
      icon: 'mdi-calendar-star',
      route: { name: 'PageTeacherEventBrowse', params: { object: 'event' } },
      path: 'event',
    },
    {
      name: 'Teachers',
      translatableName:'model.name.teachers',
      icon: 'mdi-human-male-board',
      route: { name: 'PageTeacherTeacherBrowse', params: { object: 'teacher' } },
      path: 'teacher',
    },
  ],
  Family: [
    {
      name: 'Dashboard',
      icon: 'mdi-google-classroom',
      route: { name: 'PageFamilyDashboard' },
    },
    {
      name: 'Events',
      icon: 'mdi-calendar-star',
      route: { name: 'PageFamilyEventBrowse' },
    },
    {
      name: 'Homeworks',
      icon: 'mdi-bag-personal',
      route: { name: 'PageTestHomework'},
    },
    {
      name: 'Logs',
      icon: 'mdi-help',
      route: { name: 'PageTestLog'},
    },
  ],
  Educator: [
    {
      name: 'Dashboard',
      icon: 'mdi-google-classroom',
      route: { name: 'PageEducatorDashboard' },
    },
    {
      name: 'Classrooms',
      icon: 'mdi-google-classroom',
      route: { name: 'PageTestSchoolClassroom'},
    },
    {
      name: 'Homeworks',
      icon: 'mdi-bag-personal',
      route: { name: 'PageTestHomework'},
    },
    {
      name: 'Students',
      icon: 'mdi-account-group',
      route: { name: 'PageTestSchoolStudent'},
    },
    {
      name: 'Teachers',
      icon: 'mdi-human-male-board',
      route: { name: 'PageTestSchoolTeacher'},
    },
    {
      name: 'Logs',
      icon: 'mdi-help',
      route: { name: 'PageTestLog'},
    },
  ],
}