<template>
  <v-container class="my-5 py-10 px-5">
    <div class="text-h3 mb-10 text-center"><span class="mouse-memoirs">Listen To What Parents Said</span></div>
  </v-container>
  </template>
  
  <script>
    import { mapState } from 'vuex'
    export default {
      components:{
        //
      },
      computed: mapState({
        //
      }),
      props:[
        //
      ],
      data: () => ({
        //
      }),
      created() {
        //
      },
      mounted() {
        //
      },
      methods: {
        //
      }
    }
  </script>
  
  <style scoped>
  .mouse-memoirs {
    font-family: "Mouse Memoirs", sans-serif;
  }
  </style>