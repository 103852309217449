<template>
<v-container>
  <SlideLogin/>
  <SlideBanner/>
  <SlideCharacter/>
  <SlideFeature/>
  <SlideKid/>
  <!-- Change Slide Kid to art gallery and current SlideKid as V6SlideSchoolR2 -->
  <SlideSchool class="d-none"/>
  <SlideTestimony class="d-none"/>
  <SlidePricing/>
  <SlideDemo/>
  <SlideSponsor/>
  <SlideFooter/>
  <SlideCopyright/>
</v-container>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

import SlideBanner from "@/components/views/PageMainHome/V6SlideBanner.vue";
import SlideCharacter from "@/components/views/PageMainHome/V6SlideCharacter.vue";
import SlideCopyright from "@/components/views/PageMainHome/V3SlideCopyright.vue";
import SlideDemo from "@/components/views/PageMainHome/V6SlideDemo.vue";
import SlideFeature from "@/components/views/PageMainHome/V6SlideFeatureR2.vue";
import SlideFooter from "@/components/views/PageMainHome/V6SlideFooter.vue";
import SlideKid from "@/components/views/PageMainHome/V6SlideKid.vue";
import SlideLogin from "@/components/views/PageMainHome/V3SlideLogin.vue";
import SlidePricing from "@/components/views/PageMainHome/V6SlidePricing.vue";
import SlideSchool from "@/components/views/PageMainHome/V6SlideSchool.vue";
import SlideSponsor from "@/components/views/PageMainHome/V6SlideSponsor.vue";
import SlideTestimony from "@/components/views/PageMainHome/V6SlideTestimony.vue";
export default {
  components: {
    SlideBanner,
    SlideCharacter,
    SlideCopyright,
    SlideDemo,
    SlideFeature,
    SlideFooter,
    SlideKid,
    SlideLogin,
    SlidePricing,
    SlideSchool,
    SlideSponsor,
    SlideTestimony,
  },
  data: () => ({
    //
  }),
  mounted() {
    const isMobile = window.innerWidth < 768; // Define mobile breakpoint
    AOS.init({
      offset: isMobile ? 350 : 300, // Use a larger offset for mobile
      threshold: 1,
      disable: function () {
        return isMobile; // Disable AOS if initiated with small screen
      },
    });
  }
};
</script>
