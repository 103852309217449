import Moderator from '@/models/bread/Moderator/bookReport'

export default {
  key:'Book',
  server:'game',
  name:{
    singular:'Book',
    plural:'Books',
  },
  parents:[],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}