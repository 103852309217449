import i18n from "@/plugins/i18n";
import moment from "moment";
export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
          filterable: false,
        },
        {
          text: "Name",
          value: "translatableName",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "Published",
          value: "isPublished",
        },
        {
          text: "Image",
          value: "drawing",
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
      {
        key: 'PlanPricing',
        label:  'Pricing',
      },
      {
        key: 'PlanFeature',
        label:  'Feature',
      },
    ],
    table: {
      headers: [
        {
          text: "Name",
          value: "translatableName",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "Published",
          value: "isPublished",
        },
        {
          text: "Image",
          value: "drawing",
        },
        {
          text: 'Start Date',
          value: "timestampStart",
          filterable: false,
        },
        {
          text: 'End Date',
          value: "timestampEnd",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add:{
    fields:[
      {
        type:'string',
        key:'translatableName',
        name:'Theme Name',
        defaultValue:'{"en":"Example Theme","zh":"示例主题","ms":"Contoh Tema"}'
      },
      {
        type:'number',
        key:'year',
        name:'Year',
        defaultValue:moment().format('YYYY'),
      },
      {
        type:'number',
        key:'month',
        name:'Month',
        min:1,
        max:12,
        defaultValue:moment().add(1,'months').format('M'),
      }
    ],
    rules:{
      translatableName: [v => !!v || 'Prize name is required'],
    },
  },
  edit:true,
  delete: true,
};
