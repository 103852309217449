<template>
<div class="my-5">
<v-card style="overflow: hidden;">
  <v-row class="py-0 my-0">
    <v-col sm="6" class="py-0 my-0">
      <v-img
        contain
        :src="require('@/assets/PageMainHomeV6/BannerBrightFuture.png')"
      ></v-img>
    </v-col>
    <v-col sm="6" class="text-center d-flex justify-center flex-column">
      <div class="text-h2 mb-4">{{  title[locale] }}</div>
      <div class="mb-4">{{ description[locale] }}</div>
      <div>
        <v-btn x-large color="yellow darken-5" rounded class="shiny-golden-btn animate-glow" :to="{name:'PageMainRegister'}">
          <span class="brown--text">{{ cta[locale] }}</span>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</v-card>
</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      locale: "en", 
      title: {
        en: "Brighten Your Children's Future",
        ms: "Terangi Masa Depan Anak Anda",
        zh: "照亮您孩子的未来",
      },
      description: {
        en: "Discover fun and engaging learning with Hamochi and his friends.",
        ms: "Terokai pembelajaran yang menyeronokkan dan interaktif bersama Hamochi dan rakan-rakannya.",
        zh: "与 Hamochi 和他的朋友们一起学习。",
      },
      cta: {
        en: "Get Started for FREE!",
        ms: "Mulakan Secara PERCUMA!",
        zh: "立即开始！",
      },
    }),
    created() {
      //
    },
    mounted() {
      this.locale = this.$_getLocale();
    },
    methods: {
      //
    }
  }
</script>

<style scoped>
.shiny-golden-btn {
  background: linear-gradient(135deg, #ffcc33, #ffd700); /* Base golden gradient */
  border: 2px solid #e6b800; /* Border matches the golden theme */
}
</style>