<template>
  <div class="my-5 py-10">
    <div class="text-h3 mb-5 text-center"><span class="mouse-memoirs">{{ title[locale] }}</span></div>
    <v-row style="overflow-x: hidden;">
      <v-col v-for="(item, index) in items" :key="index" sm="6">
        <v-card :href="item.href" target="_blank" class="mb-3 overflow-hidden">
          <div class="d-flex align-center">
            <v-img :src="item.imagePath" lazy cover style="width: 50%;"></v-img>
            <div class="text-center" style="width: 50%;">
              <v-card-title class="d-flex justify-center">{{ item.name[locale] }}</v-card-title>
              <v-card-subtitle>{{ item.description[locale] }}</v-card-subtitle>
              <v-card-actions>
                <v-btn x-large block color="primary" outlined rounded>{{ cta[locale] }}</v-btn>
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ImageLanguage from '@/assets/PageMainHomeV6/DemoEnglish.png'
import ImageMath from '@/assets/PageMainHomeV6/DemoMaths.png'
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    title: {
      en: "Try the Demo",
      ms: "Cuba Demo",
      zh: "试用一下",
    },
    cta: {
      en: "TRY DEMO",
      ms: "CUBA DEMO",
      zh: "马上试用",
    },
    items: [
      {
        imagePath: ImageMath,
        name: {
          en: "Maths",
          ms: "Matematik",
          zh: "数学",
        },
        description: {
          en: "Try one of the Primary 1 topics.",
          ms: "Cuba salah satu topik Tahun 1.",
          zh: "尝试一年级的其中一个课题。",
        },
        urlPath: "demo/math",
        href: "",
      },
      {
        imagePath: ImageLanguage,
        name: {
          en: "English",
          ms: "Bahasa Inggeris",
          zh: "英文",
        },
        description: {
          en: "Read one of our storybooks.",
          ms: "Baca salah satu buku cerita Hamochi.",
          zh: "阅读其中一本故事书。",
        },
        urlPath: "demo/abc?key=EN_Y010_B010",
        href: "",
      },
    ]
    ,
    locale: "en",
  }),
  created() {
    //
  },
  mounted() {
    this.locale = this.$_getLocale();
    this.items.forEach((item)=>{
      item.href = `${process.env.VUE_APP_URL_WEB_GAME}/${this.locale}/${item.urlPath}`
    })
  },
  methods: {
    //
  }
}
</script>