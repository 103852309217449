<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          color="primary"
          class="px-0 py-0"
          v-bind="attrs"
          v-on="on"
          :ripple="false"
          @click="sendInvitation"
          >{{ title }}</v-btn
        >
      </template>
      <v-card>
        <v-card-title>{{ title }}</v-card-title>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            :disabled="lang == 'ms'"
            @click="changeLanguage('ms')"
          >
            Bahasa Melayu
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="lang == 'zh'"
            @click="changeLanguage('zh')"
          >
            中文
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="lang == 'en'"
            @click="changeLanguage('en')"
          >
            English
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-textarea
            outlined
            v-model="translatedMessage"
            :label='$t("model.prop.message")'
            :value="translatedMessage"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="copied" @click="copy">
            <span v-if="copied">{{$t("action.copied")}}</span>
            <span v-else>{{$t("action.copy")}}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: ["object", "item", "title"],
  data: () => ({
    lang: "ms",
    dialog: false,
    message: {
      en: "",
      zh: "",
      ms: "",
    },
    translatedMessage: "",
    copied: false,
  }),
  created() {
    //
  },
  mounted() {},
  watch: {
		students: function (val) {
			this.translatedMessage = val;
		},
	},
  methods: {
    sendInvitation() {
      switch (this.object) {
        case "schoolMessageInvitation":
          this.message = {
            en: `Hamochi\nYou're invited to join ${this.item.name} online math game. Code: ${this.item.code}. Play now.\n${process.env.VUE_APP_URL_GAME}?code=${this.item.code}&lang=en`,
            zh: `Hamochi\n您被邀请参加 ${this.item.name} 的线上数学游戏。学校代码：${this.item.code}。马上开始玩。\n${process.env.VUE_APP_URL_GAME}?code=${this.item.code}&lang=zh`,
            ms: `Hamochi\nKamu dijemputkan untuk menghadiri permainan matematik online ${this.item.name}. Kod: ${this.item.code}. Main sekarang. \n${process.env.VUE_APP_URL_GAME}?code=${this.item.code}&lang=ms`,
          };
          break;
        case "classroomMessageInvitation":
          this.message = {
            en: `Hamochi\nYou're invited to join online math game. Game code: ${this.item.code}. Play now.\n${process.env.VUE_APP_URL_GAME}?code=${this.item.code}&lang=en`,
            zh: `Hamochi\n您被邀请参加线上数学游戏。游戏代码：${this.item.code}。马上开始玩。\n${process.env.VUE_APP_URL_GAME}?code=${this.item.code}&lang=zh`,
            ms: `Hamochi\nKamu dijemputkan untuk menghadiri permainan Hamochi matematik online. Kod: ${this.item.code}. Main sekarang. \n${process.env.VUE_APP_URL_GAME}?code=${this.item.code}&lang=ms`,
          };
          break;
        case "eventMessageInvitation":
          this.message = {
            en: `${this.item.School.name} invited you to join ${this.item.name}. School Code：${this.item.School.code}. Join now.\n${process.env.VUE_APP_URL_GAME}?code=${this.item.School.code}&lang=en`,
            zh: `${this.item.School.name} 邀请您参加${this.item.name}。学校代码：${this.item.School.code}。马上开始。\n${process.env.VUE_APP_URL_GAME}?code=${this.item.School.code}&lang=zh`,
            ms: `${this.item.School.name} menjemput kamu untuk menghadiri ${this.item.name}. Kod Sekolah：${this.item.School.code}. Sertai sekarang.\n${process.env.VUE_APP_URL_GAME}?code=${this.item.School.code}&lang=ms`,
          };
          break;
          case "accountInvitationLink":
          this.message = {
            en: `Hi, ${this.item.Owner.User.name}. You have been invited to join Hamochi! \nYou may setup your family account using following link:\n${process.env.VUE_APP_URL}/en/login?m=%2B${this.item.mobile.slice(1)}&p=${this.item.InvitedAccount[0].password}`,
            zh: `你好，${this.item.Owner.User.name}。欢迎加入 Hamochi！\n请使用以下链接设立您的家庭账号：\n${process.env.VUE_APP_URL}/en/login?m=%2B${this.item.mobile.slice(1)}&p=${this.item.InvitedAccount[0].password}`,
            ms: `Halo, ${this.item.Owner.User.name}. anda telah dijemput untuk menyertai Hamochi! \nSertai sekarang menggunakan pautan di bawah:\n${process.env.VUE_APP_URL}/en/login?m=%2B${this.item.mobile.slice(1)}&p=${this.item.InvitedAccount[0].password}`,
          };
          break;
        case "eventMessageScoreboard":
          if (this.item.password) {
            this.message = {
              en: `${this.item.name}\nYou're invited to view the scoreboard of your children. Password: ${this.item.password}. View now.\n${process.env.VUE_APP_URL_CLASSROOM}en/events/${this.item.code}?pw=${this.item.password}`,
              zh: `${this.item.name}\n邀请您查看孩子的记分牌。密码：${this.item.password}。现在查看。\n${process.env.VUE_APP_URL_CLASSROOM}zh/events/${this.item.code}?pw=${this.item.password}`,
              ms: `${this.item.name}\nAnda dijemput untuk melihat papan markah anak-anak anda. Kata laluan: ${this.item.password}. Lihat sekarang. \n${process.env.VUE_APP_URL_CLASSROOM}ms/events/${this.item.code}?pw=${this.item.password}`,
            };
          }else{
             this.message = {
              en: `${this.item.name}\nYou're invited to view the scoreboard of your children. View now.\n${process.env.VUE_APP_URL_CLASSROOM}en/events/${this.item.code}`,
              zh: `${this.item.name}\n邀请您查看孩子的记分牌。现在查看。\n${process.env.VUE_APP_URL_CLASSROOM}zh/events/${this.item.code}`,
              ms: `${this.item.name}\nAnda dijemput untuk melihat papan markah anak-anak anda. Lihat sekarang. \n${process.env.VUE_APP_URL_CLASSROOM}ms/events/${this.item.code}`,
            };
          }

          break;
      }
      this.translatedMessage = this.message[this.lang];
    },
    changeLanguage(lang) {
      this.lang = lang;
      this.translatedMessage = this.message[this.lang];
      this.copied = false;
    },
    copy() {
      navigator.clipboard.writeText(this.translatedMessage);
      this.copied = true;
      var that = this;
      setTimeout(() => {
        if (that && that.copied) that.copied = false;
      }, 800);
    },
  },
};
</script>