<template>
  <v-container class="my-5 py-10 px-5 d-flex flex-column align-center justify-center" style="overflow-x: hidden;">
    <div class="text-h3 mb-5 text-center"><span class="mouse-memoirs">{{ title[locale] }}</span></div>
    <div class="mb-5">
      <v-btn rounded color="primary" text href="https://www.facebook.com/hamochi.my/photos" target="_blank">{{
        cta[locale] }}</v-btn>
    </div>
    <div ref="swiper" class="swiper" style="width: 100%; overflow: hidden;">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="index in totalImage" :key="index"
          style="position: relative; height: 200px; width: 200px !important;">
          <div v-if="index == 1" class="d-flex flex-row" style="position: absolute; top:0; right:230px;">
            <v-img v-for="j in slidesPerView" :key="`duplicate-before-` + j" width="200px" height="200px"
              :src="require(`@/assets/PageMainHomeV6/EV2412/${totalImage - slidesPerView + j}.jpg`)"
              style="margin-left: 30px;" cover lazy>
              <template #placeholder>
                <div class="grey lighten-5" style="height: 100%;"></div>
              </template>
            </v-img>
          </div>
          <div v-if="index == totalImage" class="d-flex flex-row" style="position: absolute; top:0; left:200px;">
            <v-img v-for="k in slidesPerView" :key="`duplicate-after-` + k" width="200px" height="200px"
              :src="require(`@/assets/PageMainHomeV6/EV2412/${k}.jpg`)" style="margin-left: 30px;" cover lazy>
              <template #placeholder>
                <div class="grey lighten-5" style="height: 100%;"></div>
              </template>
            </v-img>
          </div>
          <v-img width="200" height="200" :src="require(`@/assets/PageMainHomeV6/EV2412/${index}.jpg`)" cover lazy>
            <template #placeholder>
              <div class="grey lighten-5" style="height: 100%;"></div>
            </template>
          </v-img>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

import Swiper, { Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';


export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    locale: "en", 
    title: {
      en: "Kids ❤️ Us",
      ms: "Kanak-kanak ❤️ Hamochi",
      zh: "孩子们 ❤️ Hamochi",
    },
    cta: {
      en: "View Our Photos",
      ms: "Lihat Foto Kami",
      zh: "查看過往的照片",
    },
    totalImage: 20,
    slidesPerView: 5,
    isMobile: null,
  }),
  created() {
    //
  },
  mounted() {
    this.locale = this.$_getLocale();
    this.isMobile = window.innerWidth < 768; // Define mobile breakpoint
    if (this.isMobile) this.slidesPerView = 2;

    Swiper.use([Autoplay]); // Enable Swiper's Autoplay module
    new Swiper(this.$refs.swiper, {
      freeMode: true,
      freeModeMomentum: false,
      speed: 5000,
      spaceBetween: 30,
      // initialSlide: 2,
      loop: true,
      slidesPerView: this.slidesPerView,
      centeredSlides: true,
      allowTouchMove: false,
      autoplay: {
        delay: 1,
        disableOnInteraction: false,
      },
    })
  },
  methods: {
    //
  }
}
</script>

<style scoped>
.mouse-memoirs {
  font-family: "Mouse Memoirs", sans-serif;
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}
</style>