<template>
<div>
   <!-- BOC:[header] -->
   <v-row class="mb-3">
    <v-col class="text-right">
      <v-btn
        color="primary"
        large
        :to="{name:'PageModeratorAccountSubscriptionAdd',params:{parentId:parent.id,parent:parent}}"
      >
        + New Subscription
      </v-btn>
    </v-col>
  </v-row>
  <!-- EOC -->
  <!-- BOC:[table] -->
  <BreadBrowseTable
    role="Moderator"
    :model="model"
    :url="`${$api.servers.sso}/api/v1/en/moderator/account/${parent.id}/subscription`"
    :isSearchable="true"
    :parent="parent"
    :modelParent="modelParent"
  ></BreadBrowseTable>
  <!-- EOC -->
</div>
</template>

<script>
  //BOC:[model]
  import modelParent from '@/models/items/account'
  import model from '@/models/items/accountSubscription'
  //EOC
  //BOC:[table]
  import BreadBrowseTable from '@/components/Bread/BreadBrowseTableV2'
  //EOC
  import { mapState } from 'vuex'
  export default {
    components:{
      //BOC:[table]
      BreadBrowseTable,
      //EOC
    },
    computed: mapState({
      school: state => state.school.data,
    }),
    props:[
      'role',
      'parent',
    ],
    data:()=>({
      //BOC:[model]
      modelParent:modelParent,
      model: model,
      //EOC
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>