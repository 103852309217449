<template>
  <div>
    <!-- BOC:[error] -->
    <AErrorV1 :api="api"></AErrorV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>New Feature</v-card-title>
        <v-card-text>
          <template>
            <FormInput
              :value="form['translatableName']"
              label="Translatable Name"
              :rules="nameRules"
              @input="form['translatableName'] = $event"
            />
          </template>
          <template>
            <FormTextArea
              :value="form['translatableDescription']"
              label="Translatable Description"
              :rules="summaryRules"
              @input="form['translatableDescription'] = $event"
            />
          </template>
          <template>
            <FormInput
              :value="form['key']"
              label="Key"
              :rules="keyRules"
              @input="form['key'] = $event"
            />
          </template>
          <template>
            <FormImage
              :value="form['image']"
              label="Image"
              @input="form['image'] = $event"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormInput from "@/components/Moderator/Feature/FormInput";
import FormImage from "@/components/Moderator/Feature/FormImage";
import FormTextArea from "@/components/Moderator/Feature/FormTextArea";

import axios from "axios";
import store from "../../../store";
import { mapState } from "vuex";
export default {
  props: ["data", "callbackSuccess"],
  components: {
    FormInput,
    FormImage,
    FormTextArea
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[form]
    form: {
      translatableName: '{"en":"New Feature Name","ms":"Tajuk Buku Baru","zh":"新书的书名"}',
      translatableDescription: '{"en":"","ms":"","zh":""}',
      key: null,
      image: null,
    },
    fields: [],
    rules: {},
    keyRules: [],
    nameRules: [],
    summaryRules:[],
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.sso}/api/v1/en/moderator/feature/add`;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {

    //BOC:[form]
    validate() {
      this.api.callbackReset();
      this.nameRules = [(v) => !!v || "Translatable name is required"];
      this.keyRules = [(v) => !!v || "Feature key is required"];
      // this.summaryRules = [(v) => !!v || "Book summary is required"];

      if (this.$refs.form.validate()) {
          this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    async submit() {
      // this.api.params = this.$_.clone(this.form);
      // this.$api.fetch(this.api);
      this.api.isLoading = true;
      const formData = new FormData();
      const token = "Bearer " + store.state.auth.data.Session.token;
      const config = {
        headers: { Authorization: token },
      };
      formData.append(
        "translatableName",
        this.form.translatableName
      );
      formData.append(
        "translatableDescription",
        this.form.translatableDescription
      );
      formData.append("key", this.form.key);
      formData.append("image", this.form.image);
      try {
    // Make the API request
    const response = await axios.post(
      this.$api.servers.sso + "/api/v1/en/moderator/feature/add",
      formData,
      config
    );

    this.api.isLoading = false;
    this.callbackSuccess(response.data);
  } catch (error) {
    this.api.isLoading = false;
    this.api.isLoading = false;
    this.api.isError = true;
    this.api.error = error;
  }
    },
    //EOC
  },
};
</script>

<style>
</style>