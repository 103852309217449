
import Moderator from '@/models/bread/Moderator/planPricing'


export default {
  key:'PlanPricing',
  name:{
    singular:'Pricing',
    plural:'Pricing',
  },
  parents:[
    'Plan',
  ],
  grandParents:[
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}