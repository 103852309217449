<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- <v-card class="mb-3">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          large
          :href="`https://wa.me/60123989864?text=${$t('view.PageMainRegister.message')}`"
          target="_blank"
        >
          {{ $t("action.whatsapp") }}
        </v-btn>
      </v-card-actions>
    </v-card> -->
    <v-card class="mx-auto pa-3" max-width="800" min-height="400">
      <v-alert v-if="$_getLocale() != 'en'" type="info">For now, only the English panel is available for parents, but more languages are coming soon!</v-alert>
      <v-card-title>Sign Up</v-card-title>
      <v-card-subtitle>Start the learning journey today!</v-card-subtitle>
      <AError
        v-if="otpApi.isError"
        :api="otpApi"
        :callbackReset="() => (otpApi.isError = false)"
      ></AError>
      <AError
        v-if="accountCheckApi.isError"
        :api="accountCheckApi"
        :callbackReset="() => (accountCheckApi.isError = false)"
      ></AError>
      <AError
        v-if="otpCheckApi.isError"
        :api="otpCheckApi"
        :callbackReset="() => (otpCheckApi.isError = false)"
      ></AError>
      <AError
        v-if="keyCheckApi.isError"
        :api="keyCheckApi"
        :callbackReset="() => (keyCheckApi.isError = false)"
      ></AError>
      <AError
        v-if="registerApi.isError"
        :api="registerApi"
        :callbackReset="() => (registerApi.isError = false)"
      ></AError>
      <AError
        v-if="editApi.isError"
        :api="editApi"
        :callbackReset="() => (editApi.isError = false)"
      ></AError>
      <AError
        v-if="countryApi.isError"
        :api="countryApi"
        :callbackReset="() => (countryApi.isError = false)"
      ></AError>
      <v-stepper
        v-if="step != 1 && step != 5 && !isClickSocial"
        :value="step - 1"
        class="mx-auto mb-3"
        style="max-width: 300px;"
        elevation="0"
      >
        <v-stepper-header>
          <v-stepper-step step="1" :complete="2 < step"> </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2" :complete="3 < step"> </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="4 < step"> </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <div class="d-flex align-center justify-space-around">
        <div v-if="step == 1" class="form-content text-center">
          <v-form ref="formMobile" v-model="valid" @submit.prevent="checkAcccount()">
            <!-- <div>Choose a method:</div> -->
            <v-row class="px-0 pt-3">
              <v-col cols="4">
                <v-select
                  :items="countries"
                  outlined
                  v-model="countryCode"
                  :item-text="countryNamed"
                  item-value="countryCode"
                  :loading="countryApi.isLoading"
                >
                  <template v-slot:selection="{ item }">
                    <v-img
                      contain
                      max-width="30"
                      max-height="40"
                      :src="
                        require('@/assets/Flag/' + item.countryCode + '.svg')
                      "
                    ></v-img>
                  </template>
                </v-select>
              </v-col>
              <v-col class="mx-0 px-0" cols="8">
                <TextField
                :prefix="selectedCountry && selectedCountry.phoneCode ?selectedCountry.phoneCode : null"
                  :value="mobile"
                  label="Mobile Number"
                  :rules="mobileRules"
                  type="text"
                  @input="mobile = $event"
                ></TextField>
              </v-col>
            </v-row>

            <div class="text-center pt-1 pb-2 mb-10">
              <v-btn
                width="75%"
                rounded
                color="primary"
                dark
                type="submit"
                :loading="otpApi.isLoading"
                x-large
              >
                Next
              </v-btn>
            </div>
            <div class="mb-2">Already have account? <a @click="toLogin()">Login</a></div>
              <router-link class="mb-3" :to="{name:'PageAccountForgetPassword'}">Forgot password?</router-link>
            <!-- <div class="text-center pt-2">
              <v-divider></v-divider>
            </div>
            <div class="pt-2">
              <div class="text-center pt-2">
                <v-btn
                  block
                  color="red darken-4"
                  @click="clickGoogle()"
                  class="white--text"
                >
                  <v-icon left dark> mdi-google </v-icon>
                  Google
                </v-btn>
              </div>
              <div class="text-center pt-2">
                <v-btn
                  block
                  color="blue darken-1"
                  @click="clickFacebook()"
                  class="white--text"
                >
                  <v-icon left dark> mdi-facebook </v-icon>
                  Facebook
                </v-btn>
              </div>
              <div class="text-center pt-2">
                <v-btn
                  block
                  color="black"
                  @click="clickSocial()"
                  class="white--text"
                >
                  <v-icon left dark> mdi-apple </v-icon>
                  Apple
                </v-btn>
              </div>
            </div> -->
          </v-form>
        </div>
        <div v-if="step == 2" class="form-content text-center">
          <v-form ref="formOtp" v-model="valid" lazy-validation>
            <div>
              We sent One Time Password (OTP) to
              {{ this.selectedCountry.phoneCode + this.mobile }}.
            </div>
            <div class="text-center pt-3">
              <v-text-field
                :rules="[
                  (v) => !!v || 'OTP is required',
                  () =>
                    Number.isInteger(Number(otp)) ||
                    'The OTP must be an integer number',
                ]"
                v-model="otp"
                length="6"
                block
                label="OTP"
                outlined
                clearable
              ></v-text-field>
            </div>
            <div>
              Didn't receive?
              <span v-if="resendTime > 0"> Wait {{ resendTime }} seconds.</span
              ><span v-else
                ><v-btn
                  color="primary"
                  text
                  @click="reSendOtp()"
                  :loading="otpApi.isLoading"
                >
                  Resend OTP
                </v-btn></span
              >
            </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                rounded
                color="primary"
                dark
                :loading="otpCheckApi.isLoading"
                @click="validateOtp()"
              >
                Next
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="decrease()"
              >
                Back
              </v-btn>
            </div>
          </v-form>
        </div>
        <!-- <div v-if="step == 3 && isCheckedKey" class="form-content text-center">
          <div>Choose the type of your account:</div>

          <div
            class="text-center pt-2"
            v-for="account in accountTypes"
            :key="account.value"
          >
            <v-btn
              block
              rounded
              outlined
              color="primary"
              dark
              @click="selectAccountType(account)"
            >
              {{ account.value }}
            </v-btn>
          </div>
        </div>
        <div v-if="step == 3 && !isCheckedKey" class="form-content text-center">
          <div class="text-center pt-2">
            <v-progress-circular
              :size="80"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </div> -->
        <div v-if="step == 3" class="form-content text-center">
          <v-form ref="formName" v-model="valid" lazy-validation>
            <div>What's your full name?</div>
            <div class="text-center pt-3">
              <v-text-field
                v-model="name"
                block
                label="Full Name"
                outlined
                clearable
                :rules="[(v) => !!v || 'Name is required']"
              ></v-text-field>
            </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                rounded
                color="primary"
                dark
                @click="setName()"
              >
                Next
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="decrease()"
              >
                Back
              </v-btn>
            </div>
          </v-form>
        </div>

        <div v-if="step == 4" class="form-content text-center">
          <v-form ref="formPassword" v-model="validPassword" lazy-validation>
            <div>Set your password</div>
            <div class="text-center pt-1">
              <!-- <v-text-field
                v-model="password"
                autocomplete
                label="Password"
                outlined
                clearable
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                :rules="[
                  (v) => !!v || 'Password is required',
                  (v) => (v && v.length >= 8) || 'Min 8 characters',
                ]"
              ></v-text-field> -->
              <TextField
                :value="password"
                label="Password"
                :rules="passwordRules"
                 type="password"
                @input="password = $event"
              ></TextField>
            </div>
            <div class="text-center pt-1">
              <TextField
                :value="confirmPassword"
                label="Confirm Password"
                :rules="confirmPasswordRules"
                 type="password"
                @input="confirmPassword = $event"
              ></TextField>
            </div>
            <div class="text-center pt-1">
              <v-btn
                width="75%"
                rounded
                color="primary"
                dark
                @click="register()"
              >
                Next
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn
                width="75%"
                rounded
                outlined
                color="primary"
                dark
                @click="decrease()"
              >
                Back
              </v-btn>
            </div>
          </v-form>
        </div>
        <!-- <v-card class="d-flex align-center justify-center" width="250" height="250">
          <div class="text-center">
            <v-btn rounded color="primary" dark> register </v-btn>
          </div>
      </v-card> -->
      </div>
    </v-card>
  </v-container>
</template>

<script>
import TextField from "../../components/Form/TextField.vue";
import { mapState } from "vuex";
export default {
  components: { TextField },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword || "Password must match";
    },
    ... mapState({
    invitationToken: (state) => state.invitationToken.data,
  })
  },
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    step: 1,
    isCheckedKey: false,
    name: null,
    mobile: null,
    otp: null,
    confirmPassword: null,
    password: null,
    show1: false,
    show: false,
    isClickSocial: false,
    resendTime: 60,
    setInterval: null,
    accountType: "family",
    userType: "adult",
    account: {},
    valid: true,
    validPassword:true,
    selectedCountry: null,
    countries: [],
    countryCode: "MYS",
    mobileRules: [],
    passwordRules: [],
    confirmPasswordRules:[],
    accountTypes: [
      {
        id: 1,
        value: "Student",
      },
      {
        id: 2,
        value: "Parent / Guardian",
      },
      {
        id: 2,
        value: "Teacher",
      },
    ],
    //BOC:[api]
    otpApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    otpCheckApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    keyCheckApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    registerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
     //BOC:[api]
    accountCheckApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    editApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    passportApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[api]
    countryApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  watch: {
    mobile: function () {
      this.mobileRules = [];
    },
    password: function () {
      this.passwordRules = [];
    },
    confirmPassword: function () {
      this.confirmPasswordRules = [];
    },
    resendTime: function () {
      if (this.resendTime < 1) {
        clearInterval(this.setInterval);
      }
    },
    countryCode: function (val) {
      this.countryCode = val;
      this.selectedCountry = this.$_.find(this.countries, {
        countryCode: this.countryCode,
      });
    },
  },
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("route.PageMainHome"),
      to: { name: "PageMainHome" },
      exact: true,
    });
    this.breadcrumbs.push({
      text: this.$t("route.PageMainRegister"),
      to: { name: "PageMainRegister" },
      exact: true,
    });
    //EOC
    this.countryApi.method = "GET";
    this.countryApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/data/country/";

    this.countryApi.callbackReset = () => {
      this.countryApi.isLoading = true;
      this.countryApi.isError = false;
    };

    this.countryApi.callbackError = (e) => {
      this.countryApi.isLoading = false;
      this.countryApi.isError = true;
      this.countryApi.error = e;
    };

    this.countryApi.callbackSuccess = (resp) => {
      this.countryApi.isLoading = false;
      this.countries = resp;
      this.selectedCountry = this.$_.find(this.countries, {
        countryCode: this.countryCode,
      });
    };

    this.otpApi.url =
      this.$api.servers.log + "/api/v1/" + this.$_getLocale() + "/otp/send";

    this.otpApi.callbackReset = () => {
      this.otpApi.isLoading = true;
      this.otpApi.isError = false;
    };

    this.otpApi.callbackError = (e) => {
      this.otpApi.isLoading = false;
      this.otpApi.isError = true;
      this.otpApi.error = e;
    };

    this.otpApi.callbackSuccess = () => {
      this.otpApi.isLoading = false;
      this.step = 2;
      this.resendTime = 60;
      this.startTime();
    };

    this.accountCheckApi.url =
      this.$api.servers.sso + "/api/v1/" + this.$_getLocale() + "/account/checkMobile";

    this.accountCheckApi.callbackReset = () => {
      this.accountCheckApi.isLoading = true;
      this.otpApi.isLoading = true;
      this.accountCheckApi.isError = false;
    };

    this.accountCheckApi.callbackError = (e) => {
      this.accountCheckApi.isLoading = false;
      this.otpApi.isLoading = false;
      this.accountCheckApi.isError = true;
      this.accountCheckApi.error = e;
    };

    this.accountCheckApi.callbackSuccess = (resp) => {
      this.accountCheckApi.isLoading = false;
      if(resp.isHasAccount){
        this.accountCheckApi.callbackError("The mobile number already signed up.");
      }else{
        this.sendOtp()
      }
    };


    this.otpCheckApi.url =
      this.$api.servers.log + "/api/v1/" + this.$_getLocale() + "/otp/check";

    this.otpCheckApi.callbackReset = () => {
      this.otpCheckApi.isLoading = true;
      this.otpCheckApi.isError = false;
    };

    this.otpCheckApi.callbackError = (e) => {
      this.otpCheckApi.isLoading = false;
      this.otpCheckApi.isError = true;
      this.otpCheckApi.error = e;
    };

    this.otpCheckApi.callbackSuccess = () => {
      this.otpCheckApi.isLoading = false;
      this.isCheckedKey = true;
      this.step = 3;
    };

    this.keyCheckApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/account/checkKey";
    this.keyCheckApi.callbackReset = () => {
      this.keyCheckApi.isLoading = true;
      this.keyCheckApi.isError = false;
    };

    this.keyCheckApi.callbackError = (e) => {
      this.keyCheckApi.isLoading = false;
      this.keyCheckApi.isError = true;
      this.keyCheckApi.error = e;
      this.step = 1;
    };

    this.keyCheckApi.callbackSuccess = (resp) => {
      this.keyCheckApi.isLoading = false;

      this.account = resp.Account;
      if (resp.isLogin) {
        this.$store.commit("updateAuth", resp);
        if (resp.Account.type == "educator") {
          if(this.invitationToken && this.invitationToken.token){
          this.$router.push({ name: "PageEducatorInstitutionTeacherInvite",query:{token:this.invitationToken.token} });
        }else{
          this.$router.push({ name: "PageEducatorDashboard" });
        }
        } else {
          this.$router.push({ name: "PageFamilyDashboard" });
        }
      } else {
        this.isCheckedKey = true;
      }
      console.log(resp);
    };

    this.passportApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/account/success";
    this.passportApi.method = "get";
    this.passportApi.callbackReset = () => {
      this.passportApi.isLoading = true;
      this.passportApi.isError = false;
    };

    this.passportApi.callbackError = (e) => {
      this.passportApi.isLoading = false;
      this.passportApi.isError = true;
      this.passportApi.error = e;
    };

    this.passportApi.callbackSuccess = (resp) => {
      this.passportApi.isLoading = false;
      console.log(resp);
    };

    this.registerApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/account/register";

    this.registerApi.callbackReset = () => {
      this.registerApi.isLoading = true;
      this.registerApi.isError = false;
    };

    this.registerApi.callbackError = (e) => {
      this.registerApi.isLoading = false;
      this.registerApi.isError = true;
      this.registerApi.error = e;
    };

    this.registerApi.callbackSuccess = (resp) => {
      this.registerApi.isLoading = false;
      this.$store.commit("updateAuth", resp);
      if (resp.Account.type == "educator") {
        if(this.invitationToken && this.invitationToken.token){
          this.$router.push({ name: "PageEducatorInstitutionTeacherInvite",query:{token:this.invitationToken.token} });
        }else{
          this.$router.push({ name: "PageEducatorDashboard" });
        }
      } else {
        this.$router.push({ name: "PageFamilyDashboard" });
      }
      console.log(resp);
    };

    this.editApi.url =
      this.$api.servers.sso + "/api/v1/" + this.$_getLocale() + "/account/edit";

    this.editApi.callbackReset = () => {
      this.editApi.isLoading = true;
      this.editApi.isError = false;
    };

    this.editApi.callbackError = (e) => {
      this.editApi.isLoading = false;
      this.editApi.isError = true;
      this.editApi.error = e;
    };

    this.editApi.callbackSuccess = (resp) => {
      this.editApi.isLoading = false;
      this.$store.commit("updateAuth", resp);
      if (resp.Account.type == "educator") {
        if(this.invitationToken && this.invitationToken.token){
          this.$router.push({ name: "PageEducatorInstitutionTeacherInvite",query:{token:this.invitationToken.token} });
        }else{
          this.$router.push({ name: "PageEducatorDashboard" });
        }
      } else {
        this.$router.push({ name: "PageFamilyDashboard" });
      }
    };
  },
  mounted() {
    this.$api.fetch(this.countryApi);
    //this.getDetails()
    if (this.$route.query.key) {
      this.isClickSocial = true;
      this.step = 3;
      this.checkKey();
    }
  },
  methods: {
    async getDetails() {
      //     let request = {
      //   url: this.$api.servers.account +
      //     "/v1/" +
      //     this.$_getLocale() +
      //     "/account/success", // should be replaced after going to production with domain URL
      //   withCredentials: true,
      //   method: "get",
      //   headers: {
      //    "Accept": "application/json",
      //     "Content-Type": "application/json",
      //     "Access-Control-Allow-Credentials": true,
      //   },
      //   data: null
      // };
      // const response = await axios(request);
      // console.log(response)
      //  await axios
      //     .get(
      //       this.$api.servers.account +
      //         "/v1/" +
      //         this.$_getLocale() +
      //         "/account/auth/google/success",
      //       { withCredentials: true },
      //       {
      //         headers: {
      //           "Access-Control-Allow-Credentials": true,
      //         },
      //       },
      //     )
      //     .then((response) => {
      //       console.log(response);
      //     });
    },
    checkKey() {
      this.keyCheckApi.params = {
        key: this.$route.query.key,
      };
      this.$api.fetch(this.keyCheckApi);
    },
    increase() {
      if (this.isClickSocial) {
        this.step = 6;
      } else {
        this.step++;
      }
    },
    clickSocial() {
      this.isClickSocial = true;
      this.step = 3;
    },
    toLogin() {
      this.$router.push({ name: "PageMainLogin" });
    },
    clickGoogle() {
      const googleLoginURL =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/account/auth/google/register";
      window.open(googleLoginURL, "_self");
    },
    clickFacebook() {
      const facebookLoginURL =
        this.$api.servers.sso +
        "/api/v1/" +
        this.$_getLocale() +
        "/account/auth/facebook/register";
      window.open(facebookLoginURL, "_self");
    },
    decrease() {
      if (this.step > 1) {
        if (this.step === 2) {
          this.mobile = null;
          this.$refs.formMobile && this.$refs.formMobile.reset(); // Reset form validation
          this.otp = null; // Reset OTP field
          this.$refs.formOtp && this.$refs.formOtp.reset(); // Reset OTP form validation
          clearInterval(this.setInterval);
        }
      }
      this.step--;
    },
    sendOtp() {
      //this.otpApi.callbackReset();
      this.mobileRules = [
        () =>
          (!!this.mobile &&
            this.mobile.length >= 7 &&
            this.mobile.length <= 15) ||
          "Mobile number has to be between 7 and 15",
        () =>
          Number.isInteger(Number(this.mobile)) ||
          "The Mobile number must be an integer number",
      ];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formMobile.validate()) {
          self.otpApi.params = {
            mobile: self.selectedCountry.phoneCode + self.mobile,
            countryCode: self.countryCode,
            purpose: "account_register",
          };
          self.$api.fetch(self.otpApi);
        } else {
          self.otpApi.callbackError("Please check the form.");
        }
      });
    },
    checkAcccount() {
      //this.otpApi.callbackReset();
      this.mobileRules = [
        () =>
          (!!this.mobile &&
            this.mobile.length >= 7 &&
            this.mobile.length <= 15) ||
          "Mobile number has to be between 7 and 15",
        () =>
          Number.isInteger(Number(this.mobile)) ||
          "The Mobile number must be an integer number",
      ];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formMobile.validate()) {
          self.accountCheckApi.params = {
            mobile: self.selectedCountry.phoneCode + self.mobile,
          };
          
          self.$api.fetch(self.accountCheckApi);
        } else {
          self.accountCheckApi.callbackError("Please check the form.");
        }
      });
    },
    reSendOtp() {
      this.otpApi.callbackReset();

      this.otpApi.params = {
        mobile: this.selectedCountry.phoneCode + this.mobile,
        countryCode: this.countryCode,
        purpose: "account_register",
      };
      this.$api.fetch(this.otpApi);
    },
    validateOtp() {
      if (this.$refs.formOtp.validate()) {
        this.otpCheckApi.params = {
          otp: this.otp,
          mobile: this.selectedCountry.phoneCode + this.mobile,
          purpose: "account_register",
        };
        this.$api.fetch(this.otpCheckApi);
      } else {
        this.otpApi.callbackError("Please check the form.");
      }
    },
    setName() {
      if (this.$refs.formName.validate()) {
        this.step = 4;
      } else {
        this.otpApi.callbackError("Please check the form.");
      }
    },
    startTime() {
      this.setInterval = setInterval(() => {
        if (this.resendTime > 0) {
          this.resendTime = this.resendTime - 1;
        }
      }, 1000);
    },
    selectAccountType(type) {
      if (type.value == "Teacher") {
        this.accountType = "educator";
      } else {
        this.accountType = "family";
      }
      if (type.value == "Student") {
        this.userType = "child";
      } else {
        this.userType = "adult";
      }
      if (this.isClickSocial) {
        this.updateAccount();
      } else {
        this.step++;
      }
    },
    updateAccount() {
      this.editApi.params = {
        id: this.account.id,
        accountType: this.accountType,
        userType: this.userType,
      };
      this.$api.fetch(this.editApi);
    },
    register() {
      this.passwordRules = [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Min 8 characters",
      ];
      this.confirmPasswordRules = [
                  (v) => !!v || 'Password is required',
                  this.passwordConfirmationRule,
                ]
      let self = this;
      setTimeout(function () {
        if (self.$refs.formPassword.validate()) {
          self.registerApi.params = {
            name: self.name,
            mobile: self.selectedCountry.phoneCode + self.mobile,
            accountType: self.accountType,
            userType: self.userType,
            password: self.password,
            countryCode: self.countryCode,
          };
          self.$api.fetch(self.registerApi);
        } else {
          self.registerApi.callbackError("Please check the form.");
        }
      });
    },
    countryNamed: (item) => `(${item.phoneCode}) ${item.name}`,
  },
};
</script>
<style scoped>
.form-content {
  width: 300px !important;
}
.v-stepper__header {
  box-shadow: none !important;
}
div .v-text-field__prefix {
  color: black !important;
}
</style>