import Moderator from '@/models/bread/Moderator/feature'

export default {
  key:'Feature',
  server:'sso',
  name:{
    singular:'Feature',
    plural:'Features',
  },
  parents:[],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}