<template>
  <div>
    <!-- BOC:[error] -->
    <AErrorV1 :api="api"></AErrorV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>Edit Subscription</v-card-title>
        <v-card-text>
          <template>
            <FormDate
              :callbackSelect="selectDate"
              formKey="dateStart"
              name="Start Date"
              :value="form.dateStart"
            />
          </template>
          <template>
            <FormDate
              :callbackSelect="selectDate"
              formKey="dateEnd"
              name="End Date"
              :value="form.dateEnd"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormDate from "@/components/Moderator/Subscription/FormDate";

import moment from "moment";

import { mapState } from "vuex";
export default {
  props: ["url","data", "self", "callbackSuccess"],
  components: {
    FormDate,
  },
  computed: mapState({
    
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[form]
    form: {
      id:0,
      dateStart: null,
      dateEnd: null,
      timeEnd: null,
      timeStart: null,
      timestampStart: null,
      timestampEnd: null,
    },
    fields: [],
    rules: {},
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    selectDate(date, key) {
      if (key == "dateStart") {
        this.form.dateStart = date;
      } else {
        this.form.dateEnd = date;
      }
    },
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
        this.form.timestampStart = moment(
          this.form.dateStart + " " + this.form.timeStart
        ).toISOString();
        this.form.timestampEnd = moment(
          this.form.dateEnd + " " + this.form.timeEnd
        ).toISOString();
        if (this.form.timestampStart > this.form.timestampEnd) {
          this.api.callbackError(
            "The subscription start date and time should be less than the end date and time."
          );
        } else {
          this.submit();
        }
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    //EOC
  
  },
  mounted() {
    this.form.id = this.$route.params.id;
    this.form.dateStart = moment(this.self.timestampStart).format("YYYY-MM-DD");
    this.form.dateEnd = moment(this.self.timestampEnd).format("YYYY-MM-DD");
    this.form.timeStart = moment(this.self.timestampStart).format("HH:mm");
    this.form.timeEnd = moment(this.self.timestampEnd).format("HH:mm");
  },
};
</script>

<style>
</style>