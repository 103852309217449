<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError :api="api"></AError>
    <!-- EOC -->
    <ConfirmationDialog
    v-if="event"
      :confirm= "confirm"
      :type="event.type"
      :eventCode="event.code"
      :mochiId="player.mochiId"
      
      @exitConfirm="exitConfirm"
    />
    <!-- <div class="d-flex" v-if="studentIds">
      <v-btn
        :disabled="studentIndex == 0"
        @click="previous"
        color="blue-grey"
        class="ma-2 white--text"
      >
        <v-icon left dark> mdi-arrow-left </v-icon>
        {{ $t("action.previous") }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="studentIndex == studentIds.length - 1"
        @click="next"
        color="blue-grey"
        class="ma-2 white--text"
      >
        {{ $t("action.next") }}
        <v-icon right dark> mdi-arrow-right </v-icon>
      </v-btn>
    </div> -->
    <div v-if="data.Auth || !data.password || auth.token">
      <v-card
        v-if="!api.isError"
        :loading="api.isLoading"
        elevation="0"
        class="mb-3 mx-auto"
        max-width="800"
        outlined
      >
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  <span v-if="isEducator">{{ $t("model.prop.name") }}</span
                  ><span v-else> Mochi Id </span>
                </th>
                <th style="width: 50px"></th>
                <th class="text-center">{{ $t("model.name.attempts") }}</th>
                <th class="text-center">{{ $t("model.name.submissions") }}</th>
                <th class="text-center">{{ $t("model.prop.stars") }}</th>
                <th class="text-center" v-if="event && event.type == 'math'">{{ $t("model.prop.score") }}</th>
              </tr>
            </thead>
            <tbody v-if="!playerApi.isLoading">
              <tr>
                <td>
                  <span v-if="isEducator">{{ player.name }}</span
                  ><span v-else> {{ player.mochiId }}</span>
                </td>
                <td>
                  <AAvatar
                    v-if="player.avatar"
                    :avatar="player.avatar"
                  ></AAvatar>
                  <div v-else>
                    <v-img
                      :max-height="40"
                      :max-width="40"
                      :src="require('@/assets/CS0040_default_F0.png')"
                    ></v-img>
                  </div>
                </td>
                <td class="text-center">{{ player.totalAttempt }}</td>
                <td class="text-center">{{ player.totalSubmission }}</td>
                <td class="text-center">{{ player.totalStar }} ★</td>
                <td class="text-center" v-if="event && event.type == 'math'">{{ player.totalScore }}</td>
              </tr>
            </tbody>
            <tbody v-if="playerApi.isLoading">
              <td><ASkeletonLoader type="text" width="100%" /></td>
              <td><ASkeletonLoader type="text" width="100%" /></td>
              <td><ASkeletonLoader type="text" width="100%" /></td>
              <td><ASkeletonLoader type="text" width="100%" /></td>
              <td><ASkeletonLoader type="text" width="100%" /></td>
              <td><ASkeletonLoader type="text" width="100%" /></td>
              <td v-if="event && event.type == 'math'"><ASkeletonLoader type="text" width="100%" /></td>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
      <v-card
        height="350px"
        v-if="submissions && submissions.length != 0"
        elevation="0"
        class="mb-3 mx-auto"
        max-width="800"
        outlined
      >
        <v-card-text v-if="!playerApi.isLoading && !submissionApi.isLoading">
          <v-row>
            <v-col cols="12" md="6">
              <ChartAttempt
                :start="event.timestampStart"
                :end="event.timestampEnd"
                :submission="submissions"
              />
            </v-col>
            <v-col cols="12" md="6">
              <ChartGrowth
                v-if="event && event.type == 'math'"
                :start="event.timestampStart"
                :end="event.timestampEnd"
                :submission="submissions"
                :totalAttempt="player.totalSubmission"
                :totalScore="player.totalScore"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12" md="6">
              <ASkeletonLoader type="card" width="100%" />
            </v-col>
            <v-col cols="12" md="6">
              <ASkeletonLoader type="card" width="100%" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <ScoreboardQuestion
        v-if="!playerApi.isLoading && questions.length !=0 && event.type == 'math'"
        :questions="questions"
        :event="event"
      ></ScoreboardQuestion>
      <ScoreboardChapter
        v-if="!playerApi.isLoading && questions.length !=0 && event.type == 'book'"
        :questions="questions"
        :event="event"
      ></ScoreboardChapter>
    </div>
    <div v-else>
      <v-row align="center" justify="center">
        <v-col>
          <v-card
            v-if="!$route.query.pw"
            class="mx-auto text-center"
            max-width="500"
          >
            <v-card-title>
              <div class="text-center mx-auto">
                <v-icon class="mr-2" color="black">mdi-alert</v-icon>
                <span>This contest is password protected.</span>
              </div>
            </v-card-title>
            <v-card-text>You may get the password from the host.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <Password
                class="mb-3"
                buttonText="ENTER PASSWORD"
                oldPassword="true"
                :code="$route.params.eventCode"
              />
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
          <v-card v-else class="mx-auto text-center" max-width="500">
            <v-card-title>
              <div class="text-center mx-auto">
                <v-icon class="mr-2" color="black">mdi-alert</v-icon>
                <span>Access denied.</span>
              </div>
            </v-card-title>
            <v-card-text>Please enter correct password.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <Password
                class="mb-3"
                buttonText="ENTER PASSWORD"
                pageName="PageMainEventScoreboardStudent"
                oldPassword="true"
                :code="$route.params.eventCode"
              />
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-list class="mb-3 mx-auto"
      style="background-color: transparent;"
      max-width="800">
      <div class="text-center py-2">
        <div class="text-caption">
          {{ $t("string.recalculate") }}
        </div>
        <div class="py-2 ">
        <v-btn
          small
          dark
          color="orange"
          @click="openConfirm"
        >
          {{ $t("action.recalculate") }}
          <v-icon right> mdi-reload </v-icon>
        </v-btn>
      </div>
      </div>
      </v-list>
  </v-container>
</template>

<script>
import Password from "@/components/Event/Password/Dialog";
import ChartAttempt from "@/components/Scoreboard/Student/ChartAttempt";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import ChartGrowth from "@/components/Scoreboard/Student/ChartGrowthV2";
import { mapState } from "vuex";
import ScoreboardQuestion from "@/components/Scoreboard/Student/ScoreboardQuestion.vue";
import ScoreboardChapter from "@/components/Scoreboard/Student/ScoreboardChapter.vue";
export default {
  computed: mapState({
    auth: (state) => state.auth.data,
    studentIds: (state) => state.student.data.studentIds,
  }),
  components: {
    Password,
    ScoreboardQuestion,
    ChartAttempt,
    ChartGrowth,
    ScoreboardChapter,
    ConfirmationDialog
  },
  data: () => ({
    confirm: false,
    isLoadingSubmission: false,
    questionKeyShow: null,
    chapterIdShow: null,
    studentName: null,
    eventCode: null,
    mochiId: null,
    player: {},
    isEducator: false,
    questions: [],
    event: {},
    submissions: [],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    playerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    submissionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    attemptApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    filterData: [],
    Submission: [],
    allCheckpoint: [],
    submissionLog: [],
    Attempt: [],
    weaknessQuestion: [],
    Record: [],
    studentIndex: null,
    studentId: null,
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
  }),

  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("route.PageMainHome"),
      to: { name: "PageMainHome" },
      exact: true,
    });
    this.breadcrumbs.push({
      text: this.$t("route.PageMainEvent"),
      to: { name: "PageMainEvent" },
      exact: true,
    });
    //EOC
    this.breadcrumbs.push({
      text: this.$route.params.eventCode,
      to: {
        name: "PageMainEventScoreboard",
        params: { eventCode: this.$route.params.eventCode },
      },
      exact: true,
    });
    this.breadcrumbs.push({
      text: this.$route.params.mochiId,
      to: {
        name: "PageMainEventScoreboardStudent",
        params: {
          eventCode: this.$route.params.eventCode,
          mochiId: this.$route.params.mochiId,
        },
      },
      exact: false,
    });
    //EOC

    //BOC:[api]
    this.playerApi.url =
      this.$api.servers.event +
      "/api/v1/" +
      this.$_getLocale() +
      "/main/event/" +
      this.$route.params.eventCode +
      "/player";

    this.playerApi.callbackReset = () => {
      this.playerApi.isLoading = true;
      this.playerApi.isError = false;
    };

    this.playerApi.callbackError = (e) => {
      this.playerApi.isLoading = false;
      this.playerApi.isError = true;
      this.playerApi.error = e;
    };

    this.playerApi.callbackSuccess = (resp) => {
      this.player = resp.Player;
      this.questions = resp.Topic;
      this.event = resp.Event;
      this.playerApi.isLoading = false;
      this.getSubmission()
    };

    this.submissionApi.url =
      this.$api.servers.log +
      "/api/v1/" +
      this.$_getLocale() +
      "/submission/player/" +
      this.$route.params.mochiId +
      "/getByEvent";

    this.submissionApi.callbackReset = () => {
      this.submissionApi.isLoading = true;
      this.submissionApi.isError = false;
    };

    this.submissionApi.callbackError = (e) => {
      this.submissionApi.isLoading = false;
      this.submissionApi.isError = true;
      this.submissionApi.error = e;
    };

    this.submissionApi.callbackSuccess = (resp) => {
      this.submissions = resp.Submission;
      this.submissionApi.isLoading = false;
    };
    //EOC
  },

  methods: {
    getSubmission() {
      this.submissionApi.params = this.$_.clone({
        eventId: this.event.id,
      });
      this.$api.fetch(this.submissionApi);
    },
    previous() {
      this.studentIndex = this.studentIndex - 1;
      this.$router.push({
        name: "PageMainEventScoreboardStudent",
        params: {
          eventCode: this.$route.params.mochiId,
          studentId: this.studentIds[this.studentIndex],
        },
        query: { pw: this.$route.query.pw },
      });
    },
    next() {
      this.studentIndex = this.studentIndex + 1;
      this.$router.push({
        name: "PageMainEventScoreboardStudent",
        params: {
          eventCode: this.eventCode,
          studentId: this.studentIds[this.studentIndex],
        },
        query: { pw: this.$route.query.pw },
      });
    },
    getProgress() {
      var p = 0;
      if (this.data.Record) {
        for (var r of this.data.Record) {
          if (r.topStar > 0) {
            p = p + 1;
          }
        }
        this.data.progress = p;
      }
    },
    exitConfirm() {
      this.confirm = false;
    },
    openConfirm() {
      this.confirm = true;
    },
  },
  mounted() {
    if (
      (this.$route.query.type && this.$route.query.type == "Educator") ||
      this.auth.Session
    ) {
      this.isEducator = true;
    }
    this.mochiId = this.$route.params.mochiId;
    // if (this.studentIds) {
    //   this.studentIndex = this.studentIds.indexOf(this.studentId);
    // }
    // if (this.studentIndex == -1) {
    //   this.$store.dispatch("removeStudents");
    // }
    this.eventCode = this.$route.params.eventCode;
    this.playerApi.params = this.$_.clone({ mochiId: this.mochiId });
    this.$api.fetch(this.playerApi);
    //this.fetch();
  },
};
</script>

<style>
.submission {
  background-color: #e3f2fd;
}
.submission:hover {
  background-color: #bbdefb !important;
}
</style>