import Moderator from '@/models/bread/Moderator/accountPayment'

export default {
  key:'Payment',
  server:'sso',
  name:{
    singular:'Payment',
    plural:'Payments',
  },
  parents:[
    'Account',
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}