
import i18n from '@/plugins/i18n'

export default {
  browse: {
    table: {
      headers: [
        {
          text: 'Plan',
          value: 'Plan',
        },
        {
          text: 'Amount',
          value: 'amount',
        },
        {
          text: 'Currency',
          value: 'currencyType',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Verified',
          value: 'isVerified',
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: 'Info',
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: 'Plan',
          value: 'Plan',
        },
        {
          text: 'Amount',
          value: 'amount',
        },
        {
          text: 'Currency',
          value: 'currencyType',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Verified',
          value: 'isVerified',
        },
        {
          text: i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: false,
  edit: false,
  delete: false,
}