<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
    <!-- EOC -->
    <!-- BOC:[header] -->
    <v-row class="mb-3">
      <v-col><h1>Accounts</h1></v-col>
      <v-col class="text-right">
        <v-btn
          color="primary"
          large
          :to="{ name: 'PageModeratorAccountAdd' }"
        >
          + New Account
        </v-btn>
      </v-col>
    </v-row>
    <!-- EOC -->
    <!-- BOC:[tabs] -->
    <v-tabs
      v-model="tab"
      next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows
    >
      <v-tab v-for="item in tabs" :key="item.key" :href="`#${item.key}`">
        {{ item.label }}
      </v-tab>
    </v-tabs>
    <!-- EOC -->
    <!-- BOC:[tab] -->
    <v-tabs-items v-model="tab">
      <!-- BOC:[model] -->
      <v-tab-item key="all" value="all" class="px-1 py-2">
        <!-- BOC:[table] -->
        <BreadBrowseTable
          role="Moderator"
          :model="model"
          :url="`${$api.servers.sso}/api/v2/en/moderator/account`"
          :isSearchable="true"
        ></BreadBrowseTable>
        <!-- EOC -->
      </v-tab-item>
      <!-- EOC -->
      <!-- BOC:[model] -->
      <v-tab-item key="trash" value="trash" class="px-1 py-2">
        <BreadBrowseTable
          role="Moderator"
          :model="model"
          :url="`${$api.servers.sso}/api/v2/en/moderator/account/trashed`"
          :isSearchable="true"
        ></BreadBrowseTable>
      </v-tab-item>
      <!-- EOC -->
    </v-tabs-items>
    <!-- EOC -->
  </v-container>
</template>

<script>
//BOC:[model]
import model from "@/models/items/account";
//EOC
//BOC:[table]
import BreadBrowseTable from "@/components/Bread/BreadBrowseTableV2";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    //BOC:[table]
    BreadBrowseTable,
    //EOC
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
     //BOC:[tabs]
     tab:null,
      tabs:[
        {
          key:'all',
          label:'Inbox',
        },
        {
          key:'trash',
          label:'Trash',
        },
      ],
      //EOC
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[model]
    model: model,
    //EOC
    //BOC:[role]
    role: "Moderator",
    //EOC
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("model.name.dashboard"),
      to: { name: "Page" + this.role + "Dashboard" },
      exact: true,
    });
    //
    this.breadcrumbs.push({
      text: this.$t("model.name." + this.model.name.plural.toLowerCase()),
      to: this.$_getRouteBrowse(this.role, this.model.key),
      exact: true,
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>