
<template>
<div class="my-5 py-10 px-5" style="overflow-x: hidden;">
  <div class="text-h3 mb-5 text-center"><span class="mouse-memoirs">{{ title[locale] }}</span></div>
  <div class="mb-3 text-center">{{ description[locale] }}<a href="mailto:info@hamochigames.com?subject=Partner%20School%20Inquiry">info@hamochigames.com</a></div>
  <!-- <div class="mb-3">We welcome any public and private institutions.</div> -->
  <!-- <div class="mb-3">
    <v-btn 
      rounded 
      x-large 
      color="success" 
      outlined
      :to="{name:'PageMainHome'}"
    >Become Our Partner</v-btn>
  </div> -->
  <div 
    v-for="r in 3" 
    :key="`school-badge-row-${r}`" 
    class="d-flex flex-row"
    :style="(r % 2 !== 0) ? `` : `transform: translateX(50px)`"
  >
    <v-img
      v-for="n in 10"
      :key="`school-badge-${n}`"
      width="100"
      height="100" 
      class="my-1 mx-1"
      lazy
    >
      <template #placeholder>
        <div class="grey lighten-3" style="height: 100%;"></div>
      </template>
    </v-img>
  </div>
</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      locale: "en",
      title: {
        en: "Schools Trust Us!",
        ms: "Sekolah Percayakan Kami!",
        zh: "学校信任我们！",
      },
      description: {
        en: "Want to become our partner school? Email to ",
        ms: "Ingin menjadi sekolah rakan kongsi kami? Emel kepada ",
        zh: "想成为我们的合作学校吗？发送邮件至 ",
      },

    }),
    created() {
      //
    },
    mounted() {
      this.locale = this.$_getLocale();
    },
    methods: {
      //
    }
  }
</script>

<style scoped>
.mouse-memoirs {
  font-family: "Mouse Memoirs", sans-serif;
}
</style>