import i18n from "@/plugins/i18n";
export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
          filterable: false,
        },
        {
          text: "Name",
          value: "translatableName",
        },
        {
          text: "Claimed",
          value: "_count.PlayerChapter",
        },
        {
          text: "Completed",
          value: "completedPlayerCount",
        },
        {
          text: "Percentage",
          value: "chapterCompletedPlayerPercentage",
        },
      ],
    },
  },
  read:false,
  add:false,
  edit:false,
  delete: false,
};
