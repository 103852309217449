import account from '@/models/items/account'
import accountClassroom from '@/models/items/accountClassroom'
import accountGroup from '@/models/items/accountGroup'
import accountInstitution from '@/models/items/accountInstitution'
import accountBook from '@/models/items/accountBook'
import accountPayment from '@/models/items/accountPayment'
import accountSubscription from '@/models/items/accountSubscription'
import achievement from '@/models/items/achievement'
import achievementProgress from '@/models/items/achievementProgress'
import achievementReward from '@/models/items/achievementReward'
import application from '@/models/items/application'
import book from '@/models/items/book'
import bookReport from '@/models/items/bookReport'
import building from '@/models/items/building'
import chapter from '@/models/items/chapter'
import chapterStep from '@/models/items/chapterStep'
import chapterQuestion from '@/models/items/chapterQuestion'
import chapterQuestionOption from '@/models/items/chapterQuestionOption'
import classroom from '@/models/items/classroom'
import classroomOnEvent from '@/models/items/classroomOnEvent'
import dailyGameProgress from '@/models/items/dailyGameProgress'
import dummy from '@/models/items/dummy'
import event from '@/models/items/event'
import eventBook from '@/models/items/eventBook'
import eventFinisher from '@/models/items/eventFinisher'
import eventPlayer from '@/models/items/eventPlayer'
import eventPrize from '@/models/items/eventPrize'
import eventRule from '@/models/items/eventRule'
import eventSchoolYear from '@/models/items/eventSchoolYear'
import eventSponsor from '@/models/items/eventSponsor'
import eventTopic from '@/models/items/eventTopic'
import eventOnClassroom from '@/models/items/eventOnClassroom'
import eventOnFestival from '@/models/items/eventOnFestival'
import feature from '@/models/items/feature'
import festival from '@/models/items/festival'
import festivalPrize from '@/models/items/festivalPrize'
import festivalSchool from '@/models/items/festivalSchool'
import festivalSponsor from '@/models/items/festivalSponsor'
import finisher from '@/models/items/finisher'
import theme from '@/models/items/theme'
import galleryAnalysis from '@/models/items/galleryAnalysis'
import galleryWorkshopDrawing from '@/models/items/galleryWorkshopDrawing'
import galleryAwardDrawing from '@/models/items/galleryAwardDrawing'
import game from '@/models/items/game'
import gamePrize from '@/models/items/gamePrize'
import institution from '@/models/items/institution'
import institutionClassroom from '@/models/items/institutionClassroom'
import institutionEvent from '@/models/items/institutionEvent'
import institutionStudent from '@/models/items/institutionStudent'
import institutionStudentInactive from '@/models/items/institutionStudentInactive'
import inventory from '@/models/items/inventory'
import item from '@/models/items/item'
import job from '@/models/items/job'
import log from '@/models/items/log'
import module from '@/models/items/module'
import news from '@/models/items/news'
import page from '@/models/items/page'
import participant from '@/models/items/participant'
import plan from '@/models/items/plan'
import planPricing from '@/models/items/planPricing'
import player from '@/models/items/player'
import playerBook from '@/models/items/playerBook'
import playerInventory from '@/models/items/playerInventory'
import playerStudent from '@/models/items/playerStudent'
import prize from '@/models/items/prize'
import prizeType from '@/models/items/prizeType'
import prizeTypeReward from '@/models/items/prizeTypeReward'
import quest from '@/models/items/quest'
import question from '@/models/items/question'
import questionSet from '@/models/items/questionSet'
import questionSetQuestion from '@/models/items/questionSetQuestion'
import randomItem from '@/models/items/randomItem'
import record from '@/models/items/record'
import save from '@/models/items/save'
import school from '@/models/items/school'
import seed from '@/models/items/seed'
import series from '@/models/items/series'
import seriesSponsor from '@/models/items/seriesSponsor'
import shop from '@/models/items/shop'
import shopItem from '@/models/items/shopItem'
import sponsor from '@/models/items/sponsor'
import submission from '@/models/items/submission'
import student from '@/models/items/student'
import syllabus from '@/models/items/syllabus'
import teacher from '@/models/items/teacher'
import transcript from '@/models/items/transcript'
import winner from '@/models/items/winner'

export default {
  account:account,
  accountBook: accountBook,
  accountClassroom: accountClassroom,
  accountGroup: accountGroup,
  accountInstitution : accountInstitution,
  accountPayment:accountPayment,
  accountSubscription:accountSubscription,
  achievement:achievement,
  achievementProgress:achievementProgress,
  achievementReward:achievementReward,
  application:application,
  building:building,
  book:book,
  bookReport:bookReport,
  chapter:chapter,
  chapterStep: chapterStep,
  chapterQuestion:chapterQuestion,
  chapterQuestionOption: chapterQuestionOption,
  classroom:classroom,
  classroomOnEvent: classroomOnEvent,
  dailyGameProgress:dailyGameProgress,
  dummy:dummy,
  event:event,
  eventBook:eventBook,
  eventFinisher:eventFinisher,
  eventPlayer : eventPlayer,
  eventPrize:eventPrize,
  eventRule:eventRule,
  eventSchoolYear:eventSchoolYear,
  eventSponsor:eventSponsor,
	eventTopic:eventTopic,
  eventOnClassroom: eventOnClassroom,
  eventOnFestival: eventOnFestival,
  feature:feature,
  festival:festival,
  festivalPrize:festivalPrize,
  festivalSchool:festivalSchool,
  festivalSponsor:festivalSponsor,
  finisher:finisher,
  galleryAnalysis:galleryAnalysis,
  galleryWorkshopDrawing: galleryWorkshopDrawing,
  galleryAwardDrawing: galleryAwardDrawing,
  game:game,
  gamePrize:gamePrize,
  institution:institution,
  institutionClassroom:institutionClassroom,
  institutionEvent:institutionEvent,
  institutionStudent: institutionStudent,
  institutionStudentInactive:institutionStudentInactive,
  inventory:inventory,
  item:item,
  job:job,
  log:log,
  module:module,
  news:news,
  page:page, 
  participant:participant,
  plan:plan,
  planPricing:planPricing,
  player: player,
  playerBook:playerBook,
  playerInventory: playerInventory,
  playerStudent: playerStudent,
  prize:prize,
  prizeType:prizeType,
  prizeTypeReward:prizeTypeReward,
  quest: quest,
  question:question,
  questionSet:questionSet,
  questionSetQuestion:questionSetQuestion,
  randomItem:randomItem,
  record:record,
  save:save,
  school:school,
  seed:seed,
  series:series,
  seriesSponsor:seriesSponsor,
  shop:shop,
  shopItem:shopItem,
  sponsor:sponsor,
  submission:submission,
  student:student,
  syllabus:syllabus,
  teacher:teacher,
  theme:theme,
  transcript:transcript,
  winner:winner,
}