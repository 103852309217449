export default {
  action: {
    assign_classroom: "Tugaskan bilik darjah",
    assign_event: "Tugaskan Acara",
    assign_other_school: "Tugaskan bilik darjah sekolah lain",
    assign_school_year: "Tugaskan tahun persekolahan",
    assign_school: "Tugaskan Sekolah",
    back: "Balik",
    cancel: "Batal",
    click_here: "Tekan di sini",
    confirm: "sahkan",
    copy: "Salinan",
    copied: "Disalin!",
    delete: "Padam",
    download: "Muat turun",
    download_excel: "Muat turun Excel",
    download_image: "Muat turun imej",
    edit: "Sunting",
    generate: "Menjana",
    generate_pass: "Jana Pas Ekspres",
    info: "Maklumat",
    join: "Sertai Sekarang",
    login: "Log Masuk",
    login_now: "Log Masuk",
    logout: "Log Keluar",
    manage: "Mengurus",
    manage_pass: "Urus Pas Ekspres",
    manage_prize: "Menguruskan Hadiah",
    manage_questions: "Urus Soalan",
    new_classroom: "Bilik darjah baru",
    next: "Seterusnya",
    new_event: "Acara baru",
    new_festival: "Perayaan Baru",
    new_institution: "Institusi baru",
    new_school: "Sekolah baru",
    new_sponsor: "Penaja baru",
    new_student: "Pelajar baru",
    new_teacher: "Guru Baru",
    no: "Tidak",
    ok: "okey",
    orderBy: "Susunan ikut",
    play: "Main",
    playAgain: "Main Lagi",
    previous: "Sebelumnya",
    print: "Cetak",
    quick_setup: "Persediaan Pantas",
    recalculate: "Kira semula",
    regenerateQuestion: "Tukar Contoh Soalan",
    remove: "Alih keluar",
    replace: "Ganti",
    replaceImage: "Ganti Gambar",
    returnHome: "Balik ke Halaman Utama",
    save: "Jimat",
    search: "Cari",
    select: "Pilih",
    selected: "Dipilih",
    send_invitation: "Menghantar jemputan",
    send_notice: "Hantar Notis",
    send_pass: "Hantar pas",
    set_password: "Tetapkan kata laluan",
    scoreboard: "Papan markah",
    share: "Kongsi",
    start: "Mula",
    testPlay: "Cuba",
    view: "Lihat",
    viewMore: "Lihat Lagi",
    viewSample: "Lihat Contoh",
    view_templates: "Lihat Templat",
    whatsapp: "Whatsapp Kami",
    yes: "ya",
  },
  api: {
    response: {
      no_data: "Tiada data berkaitan.",
    },
  },
  model: {
    filterBy: {
      all: "Semua",
    },
    name: {
      account: "Akaun",
      accounts: "Akaun",
      activity: "Aktiviti",
      application: "Permohonan",
      applications: "Aplikasi",
      attempts: "Percubaan",
      building: "Bangunan",
      buildings: "Bangunan",
      chapter: "Unit",
      chapters: "Unit",
      checkpoint: "Soalan",
      checkpoints: "Soalan",
      classroom: "Kelas",
      classrooms: "Kelas",
      dailyGameProgress: "Kemajuan Permainan Harian",
      dashboard: "Papan pemuka",
      event: "Aktiviti",
      events: "Aktiviti",
      feature: "Ciri",
      features: "Ciri",
      // festival:"Perayaan",
      // festivals:"Perayaan",
      finisher: "Penamat",
      finishers: "Penamat",
      game: "Permainan",
      institution: "Institusi",
      institutions: "Institusi",
      inventory: "Inventori",
      inventories: "Inventori",
      job: "Kerja",
      jobs: "Pekerjaan",
      labRecord: "Catatan",
      labRecords: "Catatan",
      member: "Ahli",
      members: "Ahli",
      news: "Berita",
      page: "Halaman",
      pages: "Halaman",
      option: "Pilihan",
      options: "Pilihan",
      participant: "Peserta",
      participants: "Peserta",
      payment: "Pembayaran",
      payments: "Pembayaran",
      performance: "Prestasi",
      plan: "Rancang",
      plans: "Rancangan",
      player: "pemain",
      players: "pemain",
      pricing: "Penentuan harga",
      prize: "Hadiah",
      prizes: "Hadiah",
      quest: "Pencarian",
      quests: "Pencarian",
      record: "Rekod",
      records: "Rekod",
      rewards: "Ganjaran",
      rule: "Peraturan",
      rules: "Peraturan",
      series: "Siri",
      step: "Langkah",
      steps: "Langkah",
      topic: "Topik",
      topics: "Topik",
      question: "Soalan",
      questions: "Soalan",
      school: "Sekolah",
      schools: "Sekolah",
      schoolyear: "Tahun sekolah",
      schoolyears: "Tahun sekolah",
      sponsor: "Penaja",
      sponsors: "Penaja",
      student: "Pelajar",
      students: "Pelajar",
      submission: "Kertas Jawapan",
      submissions: "Kertas Jawapan",
      subscription: "Langganan",
      subscriptions: "Langganan",
      syllabus: "Silibus",
      syllabuses: "Silibus",
      teachers: "guru-guru",
      teacher: "cikgu",
      theme: "tema",
      themes: "Tema",
      user: "pengguna",
      users: "Pengguna",
      winner: "Pemenang",
      winners: "Pemenang",
    },
    prop: {
      action: "Tindakan",
      accuracy: "Ketepatan",
      attendance: "Kehadiran",
      attempt: "Percubaan",
      code: "Kod",
      corrects: "Betul",
      count: "Kira",
      created: "Dicipta",
      data: "Data",
      dateEnd: "Tarikh Tamat",
      dateStart: "Tarikh Bermula",
      description: "Penerangan",
      emblem: "Lambang Sekolah",
      end: "Tamat",
      lastUpdated: "Kemas Kini Terakhir",
      logo: "Logo",
      finishers: "Penamat",
      fullname: "Nama Penuh",
      gameUrl: "Url Permainan",
      gender: "Jantina",
      isActive: "Aktif",
      itemName: "Benda",
      message: "Mesej",
      mobile: "Nombor Telefon Bimbit",
      name: "Nama",
      nameClassroom: "Nama bilik darjah",
      nameEvent: "Nama acara",
      nameSchool: "Nama sekolah",
      no: "#",
      participants: "Peserta",
      password: "Kata Laluan",
      progress: "Kemajuan",
      quantity: "Bilangan",
      questions: "问题",
      subtitle: "Sari kata",
      schoolCode: "Kod Sekolah: ",
      schoolYear: "Darjah",
      score: "Markah",
      stars: "Bintang",
      state: "Negeri",
      status: "Status",
      students: "Pelajar",
      studentCode: "Kod Pelajar: ",
      submissions: "Penyerahan",
      time: "Masa",
      timeEnd: "Masa Tamat",
      timeStart: "Masa Mula",
      timestampEnd: "Tamat",
      timestampStart: "Mula",
      total: "Jumlah",
      totalAttempt: "Percubaan",
      totalSubmission: "Jumlah Penyerahan",
      totalScore: "Markah",
      totalStar: "Bintang",
      town: "Pekan",
      tried: "Dah cuba",
      weakness: "Kelemahan",
      websiteUrl: "URL laman web",
      year: "Tahun",
    },
    value: {
      status: {
        absent: "Tidak hadir",
        active: "Aktif",
        coming: "Belum bermula",
        completed: "Siap",
        disable: "Lumpuhkan",
        enable: "Dayakan",
        inactive: "Tidak aktif",
        joined: "Telah berserta",
        ongoing: "Sudah bermula",
        past: "Sudah tamat",
      },
    },
  },
  route: {
    PageMainAccountLogin: "Log Masuk",
    PageMainApply: "Permohonan Untuk Hamochi Edu-Cup 2024/2025",
    PageMainDiscover: "Soalan",
    PageMainDiscoverQuiz: "Kuiz",
    PageMainDiscoverSample: "Contoh Soalan",
    PageMainEvent: "Pertandingan",
    PageMainEventAnalysis: "Analisis",
    PageMainEventFollowup: "Susulan",
    PageMainEventGuide: "Garis Panduan",
    PageMainEventScoreboard: "Papan Markah",
    PageMainHome: "Halaman Utama",
    PageMainLanguage: "Tukar Bahasa",
    PageMainLogin: "Log Masuk",
    PageMainPricing: "Pelan & Harga",
    PageMainRegister: "Daftar",
    PageMainUpgrade: "Pelan Peningkatan",
  },
  rules: {
    classroom: "Bilik darjah diperlukan",
    confirm_password: "Sahkan Kata Laluan diperlukan",
    count: "Kiraan diperlukan",
    event: "Acara diperlukan",
    general: "Sila betulkan borang anda.",
    logo: "Saiz imej hendaklah kurang daripada 2 MB!",
    min8Characters: "Sekurang-kurangnya 8 huruf.",
    mobile: "Nombor mudah alih mestilah antara 7 dan 15",
    mobileNumber: "Nombor Mudah Alih mestilah nombor integer",
    name: "Nama diperlukan",
    nameClassroom: "Nama bilik darjah diperlukan",
    nameEvent: "Nama acara diperlukan",
    nameSchool: "Nama sekolah diperlukan",
    new_password: "Kata Laluan Baharu diperlukan",
    notBlank: "Sila isikan.",
    old_password: "Kata Laluan Lama diperlukan",
    password: "Kata laluan mestilah 4 aksara",
    passwordMustMatch: "Kata laluan mestilah sama.",
    town: "",
    websiteUrl: "Url tapak web diperlukan",
  },
  counter_suffix: {
    duration_day: "Hari",
    duration_hour: "Jam",
    duration_minute: "Minit",
    duration_second: "Saat",
    time_day: "Hari",
    time_hour: "Jam",
    time_minute: "Minit",
    time_second: "Saat",
  },
  string: {
    bread_assign: "Tugaskan ",
    bread_manage: "Mengurus ",
    bread_new: "Baru ",
    bread_template: "Templat",
    error_construction: "Akan disediakan.",
    event_past: "Aktiviti Lama",
    express_classroom: "Bilik Darjah Ekspres",
    family: "Keluarga",
    info: "Info",
    info_institution: "Info Sekolah",
    loading: "Tunggu sebentar...",
    menu: "Menu",
    my: "",
    my_institution: "Sekolah Saya",
    page: "Halaman",
    pick_logo: "Pilih logo",
    presented_by: "Disediakan oleh",
    recalculate: "Gunakan butang berikut jika jumlah bintang atau markah tidak dikira",
    recalculateScore: "Adakah anda pasti mahu mengira semula markah?",
    recalculateStar: "Adakah anda pasti mahu mengira semula bintang?",
    question_prefix: "S",
    sample_question: "Contoh Soalan",
    schoolYear_prefix: "Tahun ",
    schoolYear_suffix: "",
    self_registration: "Pendaftaran Sendiri",
    search_by_name: "Cari mengikut nama",
    step: "Langkah",
    to: "Kepada",
  },
  view: {
    PageBackendClassroom: {
      assign_event_title: "Tugaskan Acara",
      self_registration: "Pendaftaran Sendiri",
    },
    PageBackendClassroomExpress: {
      express_pass_count: "Kiraan Pas Ekspres",
      generate_pass_title: "Jana Pas Ekspres Baharu",
      manage_pass_title: "Urus Pas Ekspres",
    },
    PageBackendEvent: {
      assign_classroom: "Tugaskan Bilik Darjah",
      assign_school_year: "Tugaskan Tahun Persekolahan",
      add_new_title: "Acara Baru",
      edit_title: "Edit acara",
      image: "Imej",
      invitation: "Jemputan",
      manage_prize: "Uruskan hadiah",
      message: "Mesej",
      notice: "Notis",
      pass: "Lulus",
      scoreboard: "Papan markah",
      send_event_notice: "Hantar Notis Acara",
      send_pass: "Hantar pas",
      send_scoreboard: "Hantar Papan Skor",
      send_syllabus: "Hantar sukatan pelajaran",
      set_password: "Tetapkan kata laluan",
      template: "Templat",
      template_name: "Nama Templat",
    },
    PageBackendPasswordChange: {
      confirm_password: "Sahkan Kata Laluan",
      new_password: "Kata laluan baharu",
      old_password: "Kata laluan lama",
      password_change_title: "Tukar kata laluan",
    },
    PageBackendSchool: {
      invitation: "Jemputan",
      quick_setup_title: "Persediaan Pantas Sekolah Baharu",
    },
    PageBackendStudent: {
      add_new_1_description: "Seorang pelajar setiap baris.",
      add_new_2_description:
        "Anda boleh menyalin dan menampal nama daripada fail excel.",
      add_new_3_description:
        'Sistem kami akan menukar "L" kepada "Lelaki" dan "F" kepada "Perempuan" secara automatik.',
      add_new_title: "Pelajar Baru",
      step: "Langkah",
    },
    PageBackendTeacher: {
      add_new_title: "Guru Baru",
      edit_title: "Edit Cikgu",
    },
    PageErrorNotFound: {
      title: "Minta Maaf",
      description: "Tidak menjumpai halaman ini.",
    },
    PageErrorSessionExpired: {
      title: "Sesi Sudah Tamat",
      description: "Sila log masuk lagi.",
    },
    PageMainDiscoverQuiz: {
      my_entry: "Jawapan Saya",
      my_score: "Markah Saya",
      points: "Markah",
      secs: "Saat",
      time: "Masa",
      total_questions: "Jumlah 10 soalan.",
    },
    PageMainEventScoreboardReport: {
      comment: {
        high: "Bagus",
        low: "Berhati",
      },
      header: {
        accuracy: "Ketepatan",
        avgCorrect: "Purata Betul",
        avgQuestion: "Soalan Purata",
        comment: "Komen",
        event: "Keseluruhan",
        school: "Sekolah",
        topic: "Topik",
      },
      improvement: {
        totalImprovedStudent: "Jumlah pelajar yang meningkat",
      },
      progress: {
        collectedAllCollections: "Kutip semua kutipan",
        completedTutorial: "Siap tutorial",
        createdAvatar: "Cipta watak",
        finishedAllTopics: "Siap semua topik",
        joined: "Serta aktiviti",
        playedOneLevel: "Cuba satu topik",
        purchasedApparel: "Beli pakaian",
        registered: "Daftar akaun",
      },
      stat: {
        students: "Pelajar",
        participants: "Peserta",
        finishers: "Penamat",
        attendance: "Kehadiran",
        completed: "Kesiapan",
        submissions: "Kertas Jawapan",
        questions: "Soalan",
        corrects: "Betul",
        accuracy: "Ketepatan",
        averageQuestion: "Purata Soalan Setiap Peserta",
      },
    },
    PageMainEventScoreboard: {
      countdown_end: "Akan Tamat Pada",
      countdown_ended: "Sudah Tamat Pada",
      countdown_start: "Akan Mula Pada",
      countdown_time_days: "Hari",
      countdown_time_hours: "Jam",
      countdown_time_minutes: "Minit",
      countdown_time_seconds: "Saat",
      event_finisher_protection_title: "Kandungan Dilindungi",
      event_finisher_protection_description: "Sila isikan kod anda.",
      event_finisher_protection_failed_title: "Kod Tak Jumpa",
      event_finisher_protection_failed_description:
        "Isikan Kod Sekolah yang betul.",
      event_finisher_status_all: "Semua",
      event_finisher_status_completed: "Lengkap",
      event_finisher_status_incomplete: "Tak lengkap",
      event_pdf_total_finisher: "Jumlah Penamat:",
      event_pdf_total_winner: "Jumlah Pemenang:",
      event_pdf_total_winner_by_school:
        "Jumlah Pemenang mengikut Tahun Persekolahan:",
      event_pdf_top5_winner: "5 Pemenang Terbaik mengikut Tahun Persekolahan:",
      event_pdf_winner_list: "Senarai Pemenang:",
      highlight_title: "Berita Hari Ini",
      highlight_1_shout: "Banyak ikannya!",
      highlight_2_shout: "Banyak serangganya!",
      highlight_1_content: " telah menangkap banyak ikan.",
      highlight_2_content: " menangkap banyak rama-rama dan memimpin.",
      perfect_score: "Tiada kelemahan.",
      report: "Laporan",
    },
    PageMainEventScoreboardStudent: {
      missing_attempt:
        " rekod telah dipadamkan. Sistem akan memadamkan rekod lama secara automatik.",
    },
    PageMainFestival: {
      password_title: "Isikan Kod Sekolah",
      password_label: "Kod Sekolah",
    },
    PageMainFestivalRead: {
      analysis: "Analisis",
      join_error_title: "Oops...",
      join_error_description: "Belum mula lagi. Akan bermula pada ",
      protection_title: "Perayaan Ini Telah Dilindungi",
      protection_description: "Sila isikan Kod Sekolah.",
      protection_action: "Isi Kod",
      protection_error: "Kod Sekolah diperlukan.",
      protection_failed_title: "Kod Tak Jumpa",
      protection_failed_description: "Isikan Kod Sekolah yang betul.",
      protection_failed_error: "Kod sekolah salah.",
      sponsor_description: "Ribuan terima kasih kepada pihak penaja.",
      tab_guide: "Garis Panduan",
      tab_ranking: "Ranking",
      tab_student_allStudent: "Semua Pelajar",
      tab_student_top3: "Tiga Teratas",
      tab_topic: "Topik",
    },
    PageMainHome: {
      slide_client_title: "Sekolah-sekolah",
      slide_client_button_more: "Tunjuk Semua",
      slide_contact_title: "Perlu bantuan?",
      slide_contact_cta: "Sokongan Langsung",
      slide_event_title: "Aktiviti",
      slide_faq_title: "Soalan Lazim (FAQ)",
      slide_faq_1_question: "Adakah permainan ini perlu dibayar?",
      slide_faq_1_answer: "Tidak. Ia PERCUMA.",
      slide_faq_2_question: "Ia menyokong platform apa?",
      slide_faq_2_answer: "Ia menyokong desktop dan telefon bimbit.",
      slide_faq_3_question: "Adakah ia menyokong telefon HuaWei?",
      slide_faq_3_answer: "Ya, selagi ia mempunyai pelayar internet.",
      slide_faq_4_question: "Adakah ia menyokong Google Gradebook?",
      slide_faq_4_answer:
        "Buat masa ini tidak. Kami mungkin akan mempertimbangkan jika banyak menerima permintaan. Sila beritahu kami jika anda berminat untuk ciri ini dan kami akan memberitahu anda bila ia akan tersedia.",
      slide_faq_5_question: "Adakah ia hanya meliputi subjek selain matematik?",
      slide_faq_5_answer:
        "Buat masa ini tidak. Kami mungkin akan mempertimbangkan jika banyak menerima permintaan. Sila beritahu kami jika anda berminat untuk ciri ini dan kami akan memberitahu anda bila ia akan tersedia.",
      slide_faq_6_question: "Adakah cikgu boleh mengubah soalan?",
      slide_faq_6_answer:
        "Ya. Kami mempunyai senarai soalan-soalan yang boleh dipilih.",
      slide_faq_7_question:
        "Bagaimana hendak memastikan pelajar memasukkan nama mereka dengan betul?",
      slide_faq_7_answer:
        "Kami mempunyai ciri pilihan pra-pendaftaran yang membolehkkan cikgu untuk menyediakan akaun pelajar terlebih dahulu.",
      slide_faq_8_question:
        "Bagaimana untuk mencegah pelajar menyalin jawapan?",
      slide_faq_8_answer:
        "Setiap kali pelajar menjawab soalan, soalan secara rawak akan dijana oleh AI, oleh itu soalan akan berlainan pada setiap masa.",
      slide_faq_9_question:
        "Apa akan jadi jika ibu bapa membantu anaknya untuk menjawab soalan?",
      slide_faq_9_answer:
        "Solusi kami adalah sama dengan konsep kerja rumah. Ia memerlukan cikgu untuk memberitahu ibu bapa pelajar/anak untuk tidak masuk campur/membantu demi pelajar/anak nya untuk belajar.",
      slide_feature_title: "Ciri-ciri",
      slide_feature_1_title: "Seronok",
      slide_feature_1_description:
        "Mendorong murid-murid belajar bersama dengan kawan melalui format permainan.",
      slide_feature_2_title: "Senang",
      slide_feature_2_description:
        "Soalan selaras dengan silibus. Laporan akan disediakan secara automatik.",
      slide_feature_3_title: "Percuma",
      slide_feature_3_description:
        "Kami percaya semua kanak-kanak mempunyai hak untuk mendapat pendidikan berkualiti.",
      slide_game_title: "Mari cuba demo!",
      slide_game_description: "PERCUMA. Tiada pendaftaran.",
      slide_game_button_play: "MAIN SEKARANG",
      slide_game_image_caption:
        "Fishing Frenzy, permainan terbaru ini boleh diakseskan dari April hingga Jun 2022.",
      slide_performance_title: "",
      slide_performance_description: "",
      slide_performance_1: "",
      slide_performance_2: "",
      slide_performance_3: "",
      slide_performance_4: "",
      slide_performance_5: "",
      slide_performance_6: "",
      slide_performance_7: "",
      slide_pitch_title: "Mengadakan Pertandingan Sekolah Yang Menarik",
      slide_pitch_description: "Mendorong murid-murid belajar",
      slide_pitch_button_host: "Bagaimana ?",
      slide_point_title: "",
      slide_point_1: "",
      slide_point_2: "",
      slide_point_3: "",
      slide_point_4: "",
      slide_point_5: "",
      slide_point_6: "",
      slide_point_7: "",
      slide_social_hint_educator: "Untuk Pendidik",
      slide_social_hint_follow:
        "Dapatkan maklumat terbaru dari media sosial kami.",
      slide_social_hint_maker: "Untuk Artis",
      slide_syllabus_title: "Silibus Tersedia",
      slide_syllabus_name1: "Tahun 1 Matematik",
      slide_testimony_title: "Apa pengguna lain kata",
      slide_testimony_1_name: "SJK(C) Kampung Merbau, Guru Besar Tia Phaik Wan",
      slide_testimony_1_message:
        '"Selepas berbincang bersama guru matematik, kami berjaya memperkenalkan Hamochi kepada pelajar. Berdasarkan kelas dalam talian yang selalu membosankan, solusi ini dapat memberi peluang kepada pelajar untuk belajar matematik dengan lebih seronok dan senang pada musim Covid-19 ini. Kami telah mengadakan pertandingan dalam talian berserta hadiah dari Jun 21 sehingga 26 Jun, 2021. Terima kasih kepada Edison dan pasukannya kerana mencipta Hamochi dan memberi peluang kepada anak murid kami dalam menyertai permainan ini. Saya sangat menghargai atas semua usaha kalian.🥰 " (Diterjemahkan dari Bahasa Mandarin)',
      slide_testimony_2_name:
        "Wonder Kids Daycare Centre Kuantan, Pengetua Sekolah Lim",
      slide_testimony_2_message:
        '"Solusi ini dapat memberikan pelajar saya untuk membuat ulang kaji di mana-mana sahaja dan pada bila bila masa,ia juga banyak menjimatkan masa saya. Laporannya jelas, tidak perlu untuk menyemak kerja yang telah dihantar. Selain memberi cabaran antara satu sama lain dan bersaing dengan sihat, pelajar saya boleh lihat dan pecahkan rekod sendiri. Setelah berulangkali bermain, memori mereka menjadi lebih bagus dan tahan lama.👍 " (Terjemahkan dari bahasa Mandarin)',
      slide_testimony_3_name: "SJK(C) Hwa Lian 2, Guru Besar Hu Ee Ling",
      slide_testimony_3_message:
        '" Terima kasih Edison dan Hamochi untuk menyediakan dan memberi peluang dalam menyertai pertandingan matematik ini. Hamochi adalah permainan yang dapat memberikan pelajar belajar sambil bermain dan ia dapat meningkatkan minat mereka kepada subjek matematik. Permainan ini dapat menanamkan sifat berfikir dalam kalangan pelajar, ia juga dapat membuatkan mereka belajar dan suka akan matematik. Terima kasih. " (Diterjemahkan dari Bahasa Mandarin)',
      slide_testimony_4_name: "SJK(C) Hwa Lian 1, Guru Besar Fong Yin Leng",
      slide_testimony_4_message:
        '"... Ketika saya cuba untuk kali pertama, anak lelaki saya yang berumur 4 tahun duduk di sebelah saya dengan teruja. Dia meminta saya untuk menyiapkan semua soalan Matematik supaya dia dapat lihat pengakhiran permainan. Saya rasa permainan ini sangat bagus, ia dapat menarik minat anak-anak dan juga ibu bapa, dan membuatkan ibu bapa sanggup menemani anak mereka belajar. Apabila saya sudah menghabiskan permainan ini, anak saya meminta saya untuk bermain sekali lagi. Ketika itu, saya tahu bahawa permainan interaktif ini boleh membuat pelajar saya untuk belajar dan meminati  Matematik…"( Diterjemahkan dari Bahasa Mandarin)',
      slide_testimony_parent_title: "",
      slide_video_title: "Pengenalan",
      slide_v3_pitch_title: "Hamochi Edu-Cup 2024/2025",
      slide_v3_pitch_description_1: "",
      slide_v3_pitch_description_2:
        "Aktiviti yang bermakna semasa cuti sekolah",
      slide_v3_pitch_description_3: "",
      slide_v3_pitch_button: "Daftar Sekarang",
    },
    PageMainLogin: {
      student_title: "Saya seorang murid.",
      teacher_title: "Saya seorang guru / ibu bapa.",
    },
    PageMainPage: {
      220901: {
        schools: "",
        sponsors: "",
      },
    },
    PageMainPricing: {
      title: "Anak Anda Layak untuk Masa Depan yang Cerah!",
      cta_register: "Daftar Percuma Sekarang!",
      cta_upgrade: "Maklumat Lanjut",
      plan_basic_name: "Pelan Asas Keluarga",
      plan_basic_price: "PERCUMA",
      plan_basic_promotion: "",
      plan_premium_name: "Pelan Keluarga Masa Depan Cerah",
      plan_premium_price: "RM 16.50 / bulan",
      plan_premium_promotion: "Jimat 50% bila bayar tahunan sebelum ",
      feature_basic_family_account: "Akaun Keluarga",
      feature_basic_daily_math: "Matematik Harian",
      feature_basic_school_event: "Aktiviti Cuti Sekolah",
      feature_premium_storybook: "Buku Cerita Bulanan",
      feature_premium_math_mastery: "Kuasai Matematik",
    },
    PageMainRegister: {
      title: "Mendaftarkan Sekolah Anda",
      description:
        "Selepas menerima permohonan anda, kami akan menyediakan akaun yang berkaitan dan mengadakan pertandingan untuk murid-murid sekolah anda.",
      message: "",
      form: "https://docs.google.com/forms/d/e/1FAIpQLSd-SmdLgQ28p2DrTiVDVI5jArRXF4sGOcpU1DPfQ6V_9Unj0Q/viewform?embedded=true",
    },
    PageMainUpgrade: {
      title: "Buka Masa Depan Cerah untuk Anak Anda Hari Ini!",
      subtitle: "Hadiah Terbaik Sepanjang Masa. Anak-anak ❤️ ia.",
      plan_premium_name: "Pelan Keluarga Masa Depan Cerah",
      plan_premium_description: "Pelan ini merangkumi ciri menarik yang direka untuk meningkatkan perjalanan pembelajaran anak anda:",
      plan_premium_shoutout: "Ceriakan Pembelajaran dengan Cara Terbaik!",
      plan_premium_price: "Hanya RM 16.50 sebulan",
      plan_premium_promotion: "Tawaran Terhad! Jimat 50% dengan bayaran tahunan sebelum ",
      plan_premium_cta: "Buka Masa Depan Cerah",
    },
    V6SlideUpgradeR2: {
      header: "Bantu Anak Anda Cemerlang",
      readingHabitTitle: "📚 Jadikan Membaca Seronok 📖",
      readingHabitValue: "Bernilai RM 180",
      readingFeature1: "Nikmati buku cerita baharu setiap bulan",
      readingFeature2: "Baca bersama dengan audio interaktif",
      readingFeature3: "Tersedia dalam tiga bahasa",
      mathSkillsTitle: "🔢 Jadikan Matematik Mudah ✏️",
      mathSkillsValue: "Bernilai RM 360",
      mathFeature1: "Silibus Matematik lengkap 6 tahun",
      mathFeature2: "Permainan interaktif",
      mathFeature3: "Tingkatkan kemahiran",
      planName: "Pelan Keluarga Masa Depan Cerah",
      planValue: "Bernilai RM 540",
      planDuration: "Untuk Setahun Penuh",
      originalPrice: "Harga asal: RM 198",
      discountedPrice: "Kini hanya RM 99",
      discountChip: "Jimat 50%",
      countdownPrefix: "Tawaran tamat:",
      countdownSuffix: "🔥",
      countdownDays: "H",
      countdownHours: "J",
      countdownMinutes: "M",
      countdownSeconds: "S",
      upgradeButton: "Meningkatkan Pelan",
      giftMessage: "🎁 Hadiah terbaik. Anak anda pasti suka ❤️",
    },
    PagePerformance: {
      no_items: "Tiada Item",
      performance_chart: "Carta",
      performance_table: "Jadual",
      star_calculation_message:
        " kurang daripada 10 soalan ATAU kurang daripada 60%",
    },
  },
  log: {
    "submission/add": "Hantar jawapan",
    "player/add": "Akaun pemain dibuat",
    "player/login": "Semak masuk",
  },
};
