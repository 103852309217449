export default [
  {
    id:1,
    name:'Primary 1',
  },
  {
    id:2,
    name:'Primary 2',
  },
  {
    id:3,
    name:'Primary 3',
  },
  {
    id:4,
    name:'Primary 4',
  },
  {
    id:5,
    name:'Primary 5',
  },
  {
    id:6,
    name:'Primary 6',
  },
  // {
  //   id:100,
  //   name:'Preschool',
  // },
  // {
  //   id:9999,
  //   name:'Teacher',
  // },
]