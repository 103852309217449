<template>
<v-container>
  <!-- BOC:[breadcrumbs] -->
  <ABreadcrumbV1 :items="breadcrumbs"></ABreadcrumbV1>
  <!-- EOC -->
  <!-- BOC:[form] -->
  <PageAdd
    :role="role"
    :model="model"
    :url="`${this.$api.servers.sso}/api/v1/en/moderator/plan/${$route.params.parentId}/pricing/add`"
    action="add"
    :callbackSuccess="callbackSuccess"
  ></PageAdd>
  <!-- EOC -->
</v-container>
</template>

<script>
  //BOC:[model]
  import modelParent from '@/models/items/plan'
  import model from '@/models/items/planPricing'
  //EOC
 import PageAdd from '@/components/Moderator/PlanPricing/PageAdd'
  import { mapState } from 'vuex'
  export default {
    components:{
      PageAdd,
    },
    computed: mapState({
      school: state => state.school.data,
    }),
    props:[
      'parent',
    ],
    data: () => ({
      //BOC:[breadcrumbs]
      breadcrumbs: [],
      //EOC
      //BOC:[model]
      modelParent: modelParent,
      model: model,
      //EOC
      //BOC:[role]
      role:'Moderator',
      //EOC
    }),
    created() {
      //BOC:[breadcrumbs]
      this.breadcrumbs.push({
        text:this.$t("model.name.dashboard"),
        to:{name:'Page'+this.role+'Dashboard'},
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:this.$t("model.name." + this.modelParent.name.singular.toLowerCase()),
        to:this.$_getRouteBrowse(this.role,this.modelParent.key),
        exact:true,
      })
      //
      this.breadcrumbs.push({
        text:(this.parent && this.parent.name) ? this.parent.name : `${this.$t("model.name." + this.modelParent.name.singular.toLowerCase())} ${this.$route.params.parentId}`,
        to:this.$_getRouteRead(this.role,this.modelParent.key,this.$route.params.parentId,this.parent),
        exact:true,
      })
      //
      let path = this.$_.cloneDeep(this.breadcrumbs[this.breadcrumbs.length-1].to)
      path.query = {tab:'Chapter'}
     
      //
      this.breadcrumbs.push({
        text:`${this.$t("string.bread_new")}${this.$t("model.name." + this.model.name.singular.toLowerCase())}`,
        to:{name:'PageModeratorPlanPricingAdd',params:{parentId:this.$route.params.parentId,parent:this.parent}},
        exact:true,
      })
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      callbackSuccess() {
        let redirect = this.$_getRouteRead(this.role,this.modelParent.key,this.$route.params.parentId,this.parent)
        redirect.query = {tab:'PlanPricing'}
        this.$router.push(redirect)
      },
    }
  }
</script>