<template>
<div>
  <hr class="my-10"/>
  <v-row class="mb-5">
    <v-col cols="12" sm="6" md="4" v-for="(item, index) in items" :key="index">
      <v-btn v-if="item.route" text block :to="item.route" :target="item.target">{{ item.name ? item.name : item.translatableName[locale] }}</v-btn>
      <v-btn v-else-if="item.url" text block :href="item.url" target="_blank">{{ item.name ? item.name : item.translatableName[locale] }}</v-btn>
      <v-btn v-else text block disabled>{{ item.name ? item.name : item.translatableName[locale] }}</v-btn>
    </v-col>
  </v-row>
</div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      locale: "en",
      items: [],
    }),
    created() {
      //
    },
    mounted() {
      this.locale = this.$_getLocale();
      //
      this.items = [
        {
          route: { 
            name:"PageMainHome",
          },
          name: this.$t("route.PageMainHome"),
        },
        {
          route: { 
            name:"PageMainPricing",
          },
          name: this.$t("route.PageMainPricing"),
        },
        {
          route: { 
            name:"PageMainDiscover",
          },
          name: this.$t("route.PageMainDiscover"),
        },
        {
          url: "https://www.youtube.com/@hamochi-my/",
          translatableName: {
            en: "YouTube",
            ms: "YouTube",
            zh: "YouTube",
          },
        },
        {
          url: "https://www.facebook.com/hamochi.my/",
          translatableName: {
            en: "Facebook",
            ms: "Facebook",
            zh: "Facebook",
          },
        },
        {
          url: "https://www.instagram.com/hamochi.my/",
          translatableName: {
            en: "Instagram",
            ms: "Instagram",
            zh: "Instagram",
          },
        },
        {
          url: "https://shopee.com.my/shop/1381592205",
          translatableName: {
            en: "Shopee",
            ms: "Shopee",
            zh: "Shopee",
          },
        },
        {
          target: "_blank",
          route: {
            name:"PageMainLegalDocument",
            params: {
              key: "privacy_policy",
            },
          },
          translatableName: {
            en: "Privacy Policy",
            ms: "Dasar Privasi",
            zh: "Privacy Policy",
          },
        },
        {
          target: "_blank",
          route: {
            name:"PageMainLegalDocument",
            params: {
              key: "terms_conditions",
            },
          },
          translatableName: {
            en: "Terms & Conditions",
            ms: "Terma & Syarat",
            zh: "Terms & Conditions",
          },
        },
        {
          route: { 
            name:"PageMainLanguage",
          },
          name: this.$t("route.PageMainLanguage"),
        },
      ]
    },
    methods: {
      //
    }
  }
</script>