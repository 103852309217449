<template>
<v-btn
  href="/"
  text
  exact
  class="pa-0"
>
  <div class="d-flex align-center">
    <v-img
      alt="hamochi.my"
      class="shrink mr-2 d-flex"
      contain
      :src="require('@/assets/HeaderLogo2024.png')"
      transition="scale-transition"
      width="124"
    ></v-img>
    <v-chip
      small
      class="mr-1"
    >Ver. {{$version}}</v-chip>
    <v-chip
      v-if="env != 'production' && env != 'release'"
      small
      class="d-none d-sm-flex"
    >{{env}}</v-chip>
  </div>
</v-btn>
</template>

<script>
  export default {
    data: () => ({
      env:process.env.VUE_APP_ENV,
    }),
  }
</script>
