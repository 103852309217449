<template>
  <div>
    <!-- BOC:[error] -->
    <AErrorV1 :api="api"></AErrorV1>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AErrorV1 :api="playerApi"></AErrorV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>Link User With Player(Step {{step}}/2)</v-card-title>
        <v-card-text v-if="step == 1">
          <template>
            <FormInput :value="form['mochiId']"
              @input="form['mochiId'] = $event" />
          </template>
        </v-card-text>
        <v-card-text v-else>
          <v-card class="mx-auto" outlined>
              <v-list>
            <v-list-item
            >
              <div class="pr-6">
                <AAvatar
                  v-if="player.avatar"
                  :width="80"
                  :height="80"
                  :avatar="player.avatar"
                />
                <v-img
                  v-else
                  :max-height="80"
                  :max-width="80"
                  :src="require('@/assets/CS0040_default_F0.png')"
                ></v-img>
              </div>
              <v-list-item-content>
                <v-list-item-title class="text-left text-h5">{{
                  player.name
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  <div>
                    {{ player.mochiId }}</div
                  >
                  <div class="pt-2"> Classroom: {{
                    player.Classroom.name
                  }}</div>
                   <div class="pt-2"> Institution: {{
                    player.Institution.name
                  }}</div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            </v-list> 
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text v-if="step == 1" @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn text v-else-if="step == 2" @click="back"> Back </v-btn>
          <v-btn v-if="step == 1" :loading="playerApi.isLoading"  color="primary" @click="validate">
            Next
          </v-btn>
          <v-btn v-else-if="step == 2" :loading="api.isLoading" color="primary" @click="submit">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormInput from "@/components/Moderator/Account/User/Link/FormInput";
import { mapState } from "vuex";
export default {
  props: ["self", "url", "callbackSuccess"],
  components: {
    FormInput,
  },
  computed: mapState({
    school: (state) => state.school.data,
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    playerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    //BOC:[form]
    form: {
      id: null,
      mochiId: null,
    },
    selectedInstitutionId: null,
    fields: [],
    rules: {},
    step:1,
    player:{}
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };

    this.playerApi.method = 'GET'
    this.playerApi.callbackReset = () => {
      this.playerApi.isLoading = false;
      this.playerApi.isError = false;
      this.playerApi.error = null;
    };
    this.playerApi.callbackError = (e) => {
      this.playerApi.isLoading = false;
      this.playerApi.isError = true;
      this.playerApi.error = e;
    };
    this.playerApi.callbackSuccess = (resp) => {
      this.playerApi.isLoading = false;
      this.player = resp
      this.step = 2
    };
    //EOC
  },
  methods: {
    //BOC:[form]
    validate() {
      this.playerApi.url = `${this.$api.servers.sso}/api/v1/en/moderator/player/${this.form.mochiId}`
      if (this.$refs.form.validate()) {
        this.next();
      } else {
        this.playerApi.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    next(){
      this.$api.fetch(this.playerApi);
    },
    back(){
      this.step = 1
    }
    //EOC
  },
  mounted() {
    this.form.id = this.$route.params.id;
    this.form.mochiId = this.self.mochiId
  },
};
</script>

<style>
</style>