import Moderator from '@/models/bread/Moderator/accountSubscription'

export default {
  key:'Subscription',
  server:'sso',
  name:{
    singular:'Subscription',
    plural:'Subscriptions',
  },
  parents:[
    'Account',
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}