<template>
<div>
  <!-- BOC:[table] -->
  <BreadBrowseTable
    role="Moderator"
    :model="model"
    :url="`${$api.servers.game}/api/v1/en/moderator/player/${parent.id}/book`"
    :isSearchable="true"
    :parent="parent"
    :modelParent="modelParent"
  ></BreadBrowseTable>
  <!-- EOC -->
</div>
</template>

<script>
  //BOC:[model]
  import modelParent from '@/models/items/player'
  import model from '@/models/items/playerBook'
  //EOC
  //BOC:[table]
  import BreadBrowseTable from '@/components/Bread/BreadBrowseTableV2'
  //EOC
  import { mapState } from 'vuex'
  export default {
    components:{
      //BOC:[table]
      BreadBrowseTable,
      //EOC
    },
    computed: mapState({
      school: state => state.school.data,
    }),
    props:[
      'role',
      'parent',
    ],
    data:()=>({
      //BOC:[model]
      modelParent:modelParent,
      model: model,
      //EOC
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>