import i18n from '@/plugins/i18n'

export default {
  browse: {
    table: {
      headers: [
        {
          text: 'Plan',
          value: 'Plan',
        },
        {
          text: 'Start',
          value: 'timestampStart',
          filterable: false,
        },
        {
          text: "End",
          value: 'timestampEnd',
          filterable: false,
        },
        {
          text: 'Status',
          value: 'subscriptionStatus',
          filterable: false,
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: 'Info',
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: 'Plan',
          value: 'Plan',
        },
        {
          text: 'Start',
          value: 'timestampStart',
          filterable: false,
        },
        {
          text: "End",
          value: 'timestampEnd',
          filterable: false,
        },
        {
          text: 'Status',
          value: 'subscriptionStatus',
          filterable: false,
        },
        {
          text: i18n.t("model.prop.created"),
          value: 'timestampCreated',
          filterable: false,
        },
        {
          text:  i18n.t("model.prop.lastUpdated"),
          value: 'timestampUpdated',
          filterable: false,
        },
      ],
    },
  },
  add: true,
  edit: {
      fields: [
        {
          type: 'date',
          key: 'timestampStart',
          name: 'Date Start',
        },
        {
          type: 'date',
          key: 'timestampEnd',
          name: 'Date End',
        },
      ],
    },
  delete: true,
}