<template>
  <div class="my-5 py-10 px-5 text-center" style="overflow-x: hidden;">
    <div class="text-h3 mb-5 text-center"><span class="mouse-memoirs">{{ title[locale] }}</span></div>
    <div class="mb-5">{{ description[locale] }}<a
        href="mailto:info@hamochigames.com?subject=Sponsor%20Students%20Inquiry">info@hamochigames.com</a></div>
    <!-- <div><v-btn rounded x-large color="success" outlined></v-btn></div> -->
    <div style="max-width: 500px; margin: auto;">
      <Sponsors v-if="!sponsorApi.isLoading" :items="sponsors"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Sponsors from "@/components/Sponsor/Sponsors.vue";

export default {
  components: {
    Sponsors,
  },
  computed: mapState({
    //
  }),
  props: [
    //
  ],
  data: () => ({
    locale: "en", title: {
      en: "Thank You For The Supports!",
      ms: "Terima Kasih Atas Sokongan Anda!",
      zh: "感谢支持",
    },
    description: {
      en: "Want to Sponsor Underprivileged Students? Email to ",
      ms: "Ingin Menaja Pelajar Kurang Berkemampuan? Emel kepada ",
      zh: "想赞助贫困的学生吗？发送邮件至 ",
    },
    //BOC:[api]
    tempEventCode: "EV2412", //to speed up development, use hard coded event
    sponsorApi: {
      isLoading: true,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    sponsors: [],
  }),
  created() {
    
    this.sponsorApi.method = "get";
    this.sponsorApi.callbackReset = () => {
      this.sponsorApi.isLoading = true;
      this.sponsorApi.isError = false;
    };
    this.sponsorApi.callbackError = (e) => {
      this.sponsorApi.isLoading = false;
      this.sponsorApi.isError = true;
      this.sponsorApi.error = e;
    };
    this.sponsorApi.callbackSuccess = (resp) => {
      this.sponsorApi.isLoading = false;
      this.sponsors = resp.Sponsor.Event;
    };
    this.sponsorApi.url =
      this.$api.servers.event +
      "/api/v1/" +
      this.$_getLocale() +
      "/main/event/" +
      this.tempEventCode +
      "/scoreboard/sponsor?institutionId=0"
      "&classroomId=0";
    this.$api.fetch(this.sponsorApi);
  },
  mounted() {
    this.locale = this.$_getLocale();
  },
  methods: {
    //
  }
}
</script>

<style scoped>
.mouse-memoirs {
  font-family: "Mouse Memoirs", sans-serif;
}
</style>