
import Moderator from '@/models/bread/Moderator/playerBook'


export default {
  key:'Book',
  name:{
    singular:'Book',
    plural:'Books',
  },
  parents:[
    'Player',
  ],
  grandParents:[
  ],
  Admin:false,
  Moderator:Moderator,
  Teacher:false,
}