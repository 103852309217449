<template>
  <div class="my-5 py-10 px-5 yellow lighten-4 rounded">
    <div class="text-h3 mb-10 text-center"><span class="mouse-memoirs">{{ $t('view.PageMainPricing.title') }}</span>
    </div>
    <div class="d-flex flex-row justify-center mb-5" v-if="!auth.Session">
      <v-btn x-large color="yellow darken-5" rounded class="shiny-golden-btn animate-glow" :to="{ name: 'PageMainRegister' }"><span
          class="brown--text">{{ $t('view.PageMainPricing.cta_register') }}</span></v-btn>
    </div>
    <div class="mb-10 text-center"></div>
    <v-row justify="center">
      <v-col v-for="(plan, i) in plans" :key="`plan-${i}`" sm="6" md="4">
        <v-card class="fill-height" :class="(i == 0) ? 'basic-plan' : 'premium-plan'">
          <v-img :src="plan.imagePath" lazy cover></v-img>
          <v-card-title>{{ plan.name }}</v-card-title>
          <v-card-text v-if="i == 0" style="min-height: 90px;">
            <div class="text-h5">{{ plan.pricing }}</div>
            <div class="success--text plan-promotion">{{ plan.promotion }}</div>
          </v-card-text>
          <v-card-actions v-if="plan.cta" class="px-5">
            <v-btn outlined block rounded x-large color="primary" :to="plan.to">{{ plan.cta }}</v-btn>
          </v-card-actions>
          <v-card-text>
            <v-list dense>
              <v-list-item v-for="(feature, j) in plan.features" :key="`plan-${i}-feature-${j}`">
                <v-list-item-icon>
                  <v-icon color="green">mdi-check-circle</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ feature.name }}</v-list-item-title>
                  <!-- <v-list-item-subtitle>{{ feature.description }}</v-list-item-subtitle> -->
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
import ImageBasic from '@/assets/PageMainHomeV6/Banner640DailyMath.png'
import ImagePremium from '@/assets/PageMainHomeV6/BannerBrightFuture.png'
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    plans: [],
  }),
  created() {
    //
  },
  mounted() {
    const featureFree = [
      {
        name: this.$t('view.PageMainPricing.feature_basic_family_account'),
      },
      {
        name: this.$t('view.PageMainPricing.feature_basic_daily_math'),
      },
      {
        name: this.$t('view.PageMainPricing.feature_basic_school_event'),
      },
    ]
    const featurePremium = [
      {
        name: this.$t('view.PageMainPricing.feature_premium_storybook'),
      },
      {
        name: this.$t('view.PageMainPricing.feature_premium_math_mastery'),
      },
    ]
    this.plans = [
      {
        name: this.$t('view.PageMainPricing.plan_basic_name'),
        imagePath: ImageBasic,
        pricing: this.$t('view.PageMainPricing.plan_basic_price'),
        promotion: this.$t('view.PageMainPricing.plan_basic_promotion'),
        features: featureFree,
      },
      {
        name: this.$t('view.PageMainPricing.plan_premium_name'),
        imagePath: ImagePremium,
        pricing: this.$t('view.PageMainPricing.plan_premium_price'),
        promotion: `${this.$t('view.PageMainPricing.plan_premium_promotion')}${moment().endOf('month').format('DD/MM/YYYY')}`,
        features: featureFree.concat(featurePremium),
        cta: this.$t('view.PageMainPricing.cta_upgrade'),
        to: { name: "PageMainUpgrade" },
      },
    ]
  },
  methods: {
    //
  }
}
</script>

<style scoped>
.mouse-memoirs {
  font-family: "Mouse Memoirs", sans-serif;
}

.shiny-golden-btn {
  background: linear-gradient(135deg, #ffcc33, #ffd700);
  /* Base golden gradient */
  border: 2px solid #e6b800;
  /* Border matches the golden theme */
}

.shiny-golden-btn:hover {
  background: linear-gradient(135deg, #ffd700, #ffcc33);
  /* Reverse gradient */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.basic-plan {
  border: 1px solid #cccccc;
  /* Light grey border */
  background-color: #f9f9f9;
  /* Subtle grey background */
  color: #7d7d7d;
  /* Muted grey text color */
  box-shadow: none;
  /* Remove any shadows */
}

.premium-plan {
  border: 2px solid #ffd700;
  box-shadow: 0px 4px 10px rgba(255, 215, 0, 0.4);
}

.basic-plan .plan-promotion {
  color: #7d7d7d !important;
}
</style>