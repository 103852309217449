
import i18n from '@/plugins/i18n'
export default {
  browse:{
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text:  'Key',
          value: 'featureKey',
        },
      ],
    },
  },
  read:{
    tabs: [
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text:  'Key',
          value: 'featureKey',
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: 'model',
        key: 'featureKey',
        name: 'Feature',
        model: 'feature',
        optionText: 'name',
        server:'sso',
        optionValue: 'key',
      },
    ],
    rules: {
      featureKey: [(v) => !!v || 'Feature is required'],
    },
  },
  edit:true,
  delete:true,
}