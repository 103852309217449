<template>
  <div>
    <!-- BOC:[error] -->
    <AErrorV1 :api="api"></AErrorV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>New Pricing</v-card-title>
        <v-card-text>
          <Country
              :value="form['countryCode']"
              @input="form['countryCode'] = $event"
            />
          <template>
            <FormInput
              :value="form['priceOriginal']"
              label="Original Price"
              :rules="priceRules"
              type="number"
              @input="form['priceOriginal'] = $event"
            />
          </template>
          <template>
            <FormInput
              :value="form['discount']"
              label="Discount"
              :rules="priceRules"
               type="number"
              @input="form['discount'] = $event"
            />
          </template>
          <template>
            <FormInput
              :value="form['key']"
              label="Key"
              :rules="keyRules"
              @input="form['key'] = $event"
            />
          </template>
          <template>
            <FormInput
              :value="form['currencyType']"
              label="Currency Type"
              :rules="currencyRules"
              @input="form['currencyType'] = $event"
            />
          </template>
          <template>
            <FormInput
              :value="form['sort']"
              label="Sort"
              :rules="priceRules"
               type="number"
              @input="form['sort'] = $event"
            />
          </template>
          <template>
            <FormInput
              :value="form['duration']"
              label="Duration"
              :rules="durationRules"
               type="number"
              @input="form['duration'] = $event"
            />
          </template>
          <template>
            <FormSelect label="Duration Unit" :data="durations" :value="form['durationUnit']" @input="form['durationUnit'] = $event"></FormSelect>
          </template>
          <template>
            <FormInput
            v-if="form.type == 'sub'"
              :value="form['redirectUrl']"
              label="Redirect Url"
              @input="form['redirectUrl'] = $event"
            />
          </template>
          <template>
            <FormRadio
              :value="form['isPublished']"
              label="Published"
              @input="form['isPublished'] = $event"
            />
          </template>
          <template>
            <FormDate
              :callbackSelect="selectDate"
              formKey="dateStart"
              name="Start Date"
              :value="form.dateStart"
            />
          </template>
          <template>
            <FormTime
              :callbackSelect="selectTime"
              formKey="timeStart"
              name="Start Time"
              :value="form.timeStart"
            />
          </template>
          <template>
            <FormDate
              :callbackSelect="selectDate"
              formKey="dateEnd"
              name="End Date"
              :value="form.dateEnd"
            />
          </template>
          <template>
            <FormTime
              :callbackSelect="selectTime"
              formKey="timeEnd"
              name="End Time"
              :value="form.timeEnd"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormInput from "@/components/Moderator/PlanPricing/FormInput";
import FormSelect from "@/components/Moderator/PlanPricing/FormSelect";
import FormRadio from "@/components/Moderator/PlanPricing/FormRadio";
import FormDate from "@/components/Moderator/PlanPricing/FormDate";
import FormTime from "@/components/Moderator/PlanPricing/FormTime";
import Country from "@/components/Moderator/PlanPricing/Country";

import moment from "moment";

import { mapState } from "vuex";
export default {
  props: ["url","data", "callbackSuccess"],
  components: {
    FormInput,
    FormSelect,
    FormRadio,
    FormDate,
    FormTime,
    Country
  },
  computed: mapState({
    
  }),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    durations:[{name:'Years',key:'years'},{name:'Months',key:'months'},{name:'Days',key:'days'}],
    //BOC:[form]
    form: {
      priceOriginal: 0,
      discount: 0,
      sort:1,
      key: null,
      currencyType:'MYR',
      countryCode: 'MYS',
      duration:1,
      isPublished: false,
      durationUnit: "years",
      dateStart: null,
      dateEnd: null,
      timestampStart: null,
      timestampEnd: null,
      timeStart: null,
      timeEnd: null,
    },
    fields: [],
    rules: {},
    keyRules: [],
    nameRules: [],
    summaryRules:[],
    //EOC
  }),

  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    selectDate(date, key) {
      if (key == "dateStart") {
        this.form.dateStart = date;
      } else {
        this.form.dateEnd = date;
      }
    },
    selectTime(time, key) {
      if (key == "timeStart") {
        this.form.timeStart = time;
      } else {
        this.form.timeEnd = time;
      }
    },
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
        this.form.timestampStart = moment(
          this.form.dateStart + " " + this.form.timeStart
        ).toISOString();
        this.form.timestampEnd = moment(
          this.form.dateEnd + " " + this.form.timeEnd
        ).toISOString();
        if (this.form.timestampStart > this.form.timestampEnd) {
          this.api.callbackError(
            "The plan pricing start date and time should be less than the event end date and time."
          );
        } else {
          this.submit();
        }
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    //EOC
  },
};
</script>

<style>
</style>