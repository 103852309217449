<template>
  <v-select
    label='Country'
    class="white"
    :items="data"
    item-text="name"
    item-value="countryCode"
    v-model="inputVal"
  ></v-select>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["callbackSelect", "callbackError","value"],
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    data: [],
    id: null,
    item: null,
  }),
  created() {
    //BOC:[api]
    this.api.method = "get";
    this.api.url =`${this.$api.servers.sso}/api/v1/en/data/country`
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
      //BOC:[parent]
      this.callbackError(e);
      //EOC
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.data = resp;
    };
    //EOC
  },
   computed: {
    ...mapState({
      school: (state) => state.school.data,
    }),
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    fetch() {
      this.$api.fetch(this.api);
    },
  },
  mounted() {
    this.$api.fetch(this.api);
  },
};
</script>
