import i18n from "@/plugins/i18n";
export default {
  browse: {
    table: {
      headers: [
        {
          text: i18n.t("model.prop.no"),
          value: "index",
          sortable: false,
        },
        {
          text: i18n.t("model.prop.name"),
          value: "Book",
        },
      ],
    },
  },
  read: {
    tabs: [
      {
        key: "Info",
        label: i18n.t("string.info"),
      },
    ],
    table: {
      headers: [
        {
          text: i18n.t("model.prop.name"),
          value: "Book",
        },
        {
          text: i18n.t("model.prop.created"),
          value: "timestampCreated",
          filterable: false,
        },
        {
          text: i18n.t("model.prop.lastUpdated"),
          value: "timestampUpdated",
          filterable: false,
        },
      ],
    },
  },
  add: {
    fields: [
      {
        type: 'model',
        key: 'bookKey',
        name: 'Book',
        model: 'book',
        optionText: 'name',
        server:'game',
        optionValue: 'key',
      },
    ],
    rules: {
      bookkey: [(v) => !!v || 'Book is required'],
    },
  },
  edit: false,
  delete: true,
};
