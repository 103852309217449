
import i18n from '@/plugins/i18n'
export default {
  browse:{
    table:{
      headers: [
        {
          text:  i18n.t("model.prop.no"),
          value: 'index',
          sortable: false,
          filterable: false,
        },
        {
          text:  'Name',
          value: 'name',
        },
        {
          text:  'Progression',
          value: 'bookProgression',
        },
        {
          text:  'Last Update',
          value: 'dateUpdated',
        },
      ],
    },
  },
  read:false,
  add:false,
  edit:false,
  delete:true,
}