<template>
  <div>
    <!-- BOC:[error] -->
    <AErrorV1 :api="api"></AErrorV1>
    <!-- EOC -->
    <!-- BOC:[form] -->
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-card>
        <v-card-title>New Subscription</v-card-title>
        <v-card-text>
          <template>
            <Plan
              :callbackSelect="selectPlan"
              :callbackError="showError"
              @input="form['planKey'] = $event"
            />
          </template>
          <template>
            <Pricing
              ref="pricing"
              :plan="plan"
             :callbackSelect="selectPricing"
              :callbackError="showError"
              @input="form['planPricingKey'] = $event"
            />
          </template>
          <template>
            <FormInput
              :value="form['priceOriginal']"
              label="Original Price"
              :rules="priceRules"
              type="number"
              @input="form['priceOriginal'] = $event"
            />
          </template>
          <template>
            <FormInput
              :value="form['discount']"
              label="Discount"
              :rules="priceRules"
              type="number"
              @input="form['discount'] = $event"
            />
          </template>
          <template>
            <FormInput
              :value="form['amount']"
              label="Amount"
              :rules="keyRules"
              @input="form['amount'] = $event"
            />
          </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="$router.go(-1)"> Cancel </v-btn>
          <v-btn color="primary" :loading="api.isLoading" @click="validate">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import FormInput from "@/components/Moderator/Subscription/FormInput";
import Plan from "@/components/Moderator/Subscription/Plan";
import Pricing from "@/components/Moderator/Subscription/Pricing";

import { mapState } from "vuex";
export default {
  props: ["url", "data", "callbackSuccess"],
  components: {
    FormInput,
    Plan,
    Pricing,
  },
  computed: mapState({}),
  data: () => ({
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    plan:null,
    pricing:null,
   
    //BOC:[form]
    form: {
      priceOriginal: 0,
      discount: 0,
      planPricingKey: null,
      planKey: null,
    },
    fields: [],
    rules: {},
    keyRules: [],
    //EOC
  }),
  watch: {
  form: {
    handler() {
      this.form.amount = this.form.priceOriginal - (this.form.discount / 100) * this.form.priceOriginal;
    },
    deep: true, 
  },
},
  created() {
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  methods: {
    selectPlan(item) {
      this.plan = item;
      this.form.planKey = item.key
      var that = this;
      setTimeout(() => {
        that.$refs["pricing"].fetch();
      }, 100);
    },

    selectPricing(item) {
      this.pricing = item;
      this.form.planPricingKey = this.pricing.key
      this.form.priceOriginal = this.pricing.priceOriginal
      this.form.discount = this.pricing.discount
      this.form.amount =  this.form.priceOriginal-((this.form.discount/100) * this.form.priceOriginal) 
    },
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
          this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone(this.form);
      this.$api.fetch(this.api);
    },
    //EOC
  },
};
</script>

<style>
</style>