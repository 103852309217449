var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('ABreadcrumb',{attrs:{"items":_vm.breadcrumbs}}),(_vm.editApi.isError)?_c('AError',{attrs:{"api":_vm.editApi,"callbackReset":function () { return (_vm.editApi.isError = false); }}}):_vm._e(),(_vm.api.isError)?_c('AError',{attrs:{"api":_vm.api,"callbackReset":function () { return (_vm.api.isError = false); }}}):_vm._e(),_c('v-card',{staticClass:"mx-auto pa-3",attrs:{"max-width":"800","outlined":"","min-height":"200"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h2',[_vm._v("Edit User")])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"d-flex justify-center justify-sm-end"},[_c('div',[_c('v-btn',{attrs:{"rounded":"","color":"error","outlined":"","to":{
                name: 'PageEducatorUserDelete',
                params: { id: this.$route.params.id },
              }}},[_vm._v(" Delete ")])],1)])])],1),_c('v-card-title'),_c('div',{staticClass:"d-flex align-center justify-space-around"},[_c('div',{staticClass:"form-content text-center"},[_c('v-form',{ref:"formName",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"text-center pt-3"},[_c('TextField',{attrs:{"value":_vm.name,"label":"Full name","rules":_vm.nameRules,"type":"text"},on:{"input":function($event){_vm.name = $event}}})],1),(_vm.selectedType == 'child')?_c('div',{staticClass:"text-center pt-3"},[_c('SelectField',{attrs:{"value":_vm.schoolYear,"field":_vm.selectSchoolYearField,"label":"School Year"},on:{"input":function($event){_vm.schoolYear = $event}}})],1):_vm._e(),_c('div',{staticClass:"text-center pt-3"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date of Birth","readonly":"","outlined":"","clearable":"","rules":_vm.dobRules},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"color":"primary","max":new Date(
                    Date.now() - new Date().getTimezoneOffset() * 60000
                  )
                    .toISOString()
                    .substring(0, 10),"min":"1950-01-01"},on:{"click:date":function($event){return _vm.$refs.menu.save(_vm.date)},"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('div',{staticClass:"text-center pt-1"},[_c('v-btn',{attrs:{"width":"75%","loading":_vm.editApi.isLoading,"rounded":"","color":"primary","dark":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")])],1),_c('div',{staticClass:"text-center pt-2"},[_c('v-btn',{attrs:{"width":"75%","rounded":"","outlined":"","color":"primary","dark":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel ")])],1)])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }